// react imports
import React from "react";
import { useParams } from "react-router";

// css
import '../static/styles/common/landing.css';
import '../static/styles/common/CommonElements.css';
import '../static/styles/common/MediaQue.css';

// local imports
import NavBarWeb from '../components/BasePage/NavBarWeb';
import NavBarMob from '../components/BasePage/NavBarMob';
import WhyDetSection from '../components/BasePage/WhyDetSection';
import Footer from '../components/BasePage/Footer';
import ExplorinEmpire from '../components/BasePage/ExplorinEmpire';
import Chat from '../components/BasePage/ChatWithUs';
import ExplorationJourneyTicket from '../components/DET/ExplorationJourneyTicket';
import Prerequisites from '../components/DET/Prerequisites';
import FAQs from '../components/BasePage/FAQs';
import Pricing from '../components/MentorPage/Pricing';

//utils
import { MENTORS } from "../static/utils/mentors";

export default function BasePage() {
  const { mentorId } = useParams();
  const mentorData = MENTORS[mentorId]

  return (
    <>
      <div className='basesection'>
        <NavBarWeb mentorData={mentorData} />
        <NavBarMob mentorData={mentorData} />
        <Chat />
        <ExplorationJourneyTicket />
        <Prerequisites mentorData={mentorData} />
        <ExplorinEmpire mentorData={mentorData} />
        <WhyDetSection mentorData={mentorData}/>
        <Pricing mentorData={mentorData} />
        <FAQs />
        <Footer />
      </div>
    </>
  );
}
