// react
import React from 'react';

// images
import Background from '../../static/images/Backgrounds/start-journey-bg.svg';
import WhatItIsImg from '../../static/images/Icons/what-it-is.svg';
import WhatItIsImgMob from '../../static/images/Icons/what-it-is-mob.svg';

// css
import '../../static/styles/components/BasePage/StartJourney.css';

// urls
export default function WhatItIs() {
	return (
		<>
			<div className='journey-page what-it-is'>
				<div className='journey-head what-it-is-head'>
					<div className='journey-heading'>
						<span className='orange-text'> What </span> it is?
					</div>
					<p>A 3 hour Live Training with me to boost your Placement Preparation.</p>
					<img src={WhatItIsImgMob} className='what-it-is-img-mob' alt='whatItIs' />
				</div>
				<img src={WhatItIsImg} className='journey-steps what-it-is-img' alt='whatItIs' />
				<img
					src={Background}
					className='journey-bg '
					alt='experience'
				/>
			</div>
		</>
	);
}
