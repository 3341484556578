// react imports
import React from "react";
import { useParams } from "react-router";

// css
import '../static/styles/common/landing.css';
import '../static/styles/common/CommonElements.css';
import '../static/styles/common/MediaQue.css';
import '../static/styles/common/MentorSpecific.css';

// local imports
import NavBarWeb from '../components/BasePage/NavBarWeb';
import NavBarMob from '../components/BasePage/NavBarMob';
import Footer from '../components/BasePage/Footer';
import Chat from '../components/BasePage/ChatWithUs';
import StartJourney from '../components/BasePage/StartJourney';
import HomeSection from '../components/MentorPage/HomeSection';
import HomeSectionMetaData from '../components/MentorPage/HomeSectionMetaData';
import Pricing from '../components/MentorPage/Pricing';
import Outcomes from '../components/MentorPage/Outcomes';
import MeetMeLive from "../components/MentorPage/meetMeLive";
import PreviousWebinars from "../components/MentorPage/previousWebinars";
import WhyDetSection from "../components/BasePage/WhyDetSection";
import FAQs from "../components/BasePage/FAQs";

// utils
import { MENTORS } from '../static/utils/mentors';

export default function MentorPage() {
  const { mentorId } = useParams();
  const mentorData = MENTORS[mentorId]

  return (
    <>
      {mentorData ?
        <div className='basesection'>
          <NavBarWeb mentorData={mentorData} />
          <NavBarMob mentorData={mentorData} />
          <Chat />
          <HomeSection mentorData={mentorData} />
          <HomeSectionMetaData mentorData={mentorData} />
          <PreviousWebinars mentorData={mentorData} hideSub={true} />
          <MeetMeLive mentorData={mentorData} />
          <Outcomes mentorData={mentorData} />
          <StartJourney mentorData={mentorData} />
          <WhyDetSection mentorData={mentorData} />
          <Pricing mentorData={mentorData} />
          <FAQs />
          <Footer />
        </div>
        :
        ""}
    </>
  );
}
