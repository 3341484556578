// react imports
import React from 'react';

//css
import '../../static/styles/components/BasePage/HomeSection.css';

export default function HomeSectionMetaData({ mentorData }) {
  return (
    <>
      <br />
      {mentorData?.lowerName !== "dev" && (!mentorData?.visionVideo || mentorData?.lowerName === "nishtha" ) ?
        <center className='MetaDataIntro'>
          <h3>
            <span className="orange-text">{mentorData?.usp}</span>
          </h3>
        </center>
         :
        <><br /><br /><br /></>}
        <br />
    </>
  );
}