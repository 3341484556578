//react
import React, { useState } from 'react';
import isMobile from '../../static/utils/applicationUrls';

//css
import '../../static/styles/components/BasePage/ChatWithUs.css';

//images
import PlaneIconWhite from '../../static/images/Icons/plane_in_button.png';

export default function Chat({ mentorData }) {
  const [msg, setMsg] = useState('Hey! I need assistance about the Explorin VIT program.');

  const showTextBox = () => {
    const box = document.getElementById('txtbox')
    if (box.style.display === "none") {
      box.style.display = "flex";
      const textarea = document.getElementById('inputhelp');
      textarea.focus();
    }
    else
      box.style.display = "none"
  }

  const link = (isMobile ? 'https://wa.me/919950409065/?text=' : 'https://web.whatsapp.com/send?phone=919950409065&text=')

  return (
    <>
      <div className='clickIcon' onClick={showTextBox}></div>
      <div id='txtbox' style={{ display: 'none' }}>
        <div className='helplogo'></div>
        <div className='closehelp' onClick={showTextBox}><i className="fa fa-times" aria-hidden="true"></i></div>
        <textarea id='inputhelp' placeholder='How can we help you?' onChange={e => setMsg(e.target.value)} />
        <div>
          <a className='sendhelp' href={link + msg} target='_blank' rel="noreferrer">Chat
            <img
              alt='white-plane-icon'
              src={PlaneIconWhite}
              className='PlaneInButton helplane'
            />
          </a>
        </div>
      </div>
    </>
  );
}