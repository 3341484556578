import Neha from '../images/MentorSpecific/batch/nehaNishtha.svg';
import Diya from '../images/MentorSpecific/batch/diyaNishtha.svg';
import Pankaj from '../images/MentorSpecific/batch/pankajNishtha.svg';
import Harshita from '../images/MentorSpecific/batch/harshitaNishtha.svg';
import Vanshika from '../images/MentorSpecific/batch/vanshikaNishtha.svg';
import AnushkaShreyam from '../images/MentorSpecific/batch/anushkaShreyamNishtha.svg';
import AnushkaSingh from '../images/MentorSpecific/batch/anushkaSinghNishtha.svg';
import Mahima from '../images/MentorSpecific/batch/mahimaNishtha.svg';
import Avinash from '../images/MentorSpecific/batch/avinashDev.svg';
import SatyamRaj from '../images/MentorSpecific/batch/satyamRajDev.svg';
import Ashi from '../images/MentorSpecific/batch/ashiDev.svg';
import Gyapti from '../images/MentorSpecific/batch/gyaptiNishtha.svg';
import Navya from '../images/MentorSpecific/batch/navyaNishtha.svg';
import Anjali from '../images/MentorSpecific/batch/anjaliNishtha.svg';
import Prabhjot from '../images/MentorSpecific/batch/prabhjotNishtha.svg';
import Deepanshi from '../images/MentorSpecific/batch/deepanshiNishtha.svg';
import Yukti from '../images/MentorSpecific/batch/yuktiNishtha.svg';
import Namita from '../images/MentorSpecific/batch/namitaNishtha.svg';
import Anonymous from '../images/MentorSpecific/batch/anonymous.svg';

export const BATCH = {
  nishtha: [
    {
      name: "Anjali Sarowa",
      linkedin: "https://www.linkedin.com/in/anjali-sarowa-889aa7228",
      description: "Nishtha di has a very strong background in tech and is definitely an inspiration. I believe that her guidance will provide me with the right guidance to achieve my goal.",
      college: "IGDTUW",
      branch: "BTech, CSE",
      image: Anjali,
      passName: "Standard Pass",
    }, {
      name: "Prabhjot Singh",
      linkedin: "https://www.linkedin.com/in/prabhjot-singh-chugh",
      description: "Being chosen for this opportunity means so much to me, and I am honored and humbled to have been selected. I am truly grateful to the whole team of Explorin, for the chance to learn from someone as experienced and knowledgeable as my mentor, Nishtha Ma'am.",
      college: "Kurukshetra University",
      branch: "BTech, CSE - ML & AI",
      image: Prabhjot,
      passName: "Standard Pass",
    }, {
      name: "Deepanshi Gupta",
      linkedin: "https://www.linkedin.com/in/deepanshi-gupta-055931229",
      description: "I am extremely elated to be a part of Nishtha's Super 30 Batch. I believe that this opportunity to learn and grow together with such hardworking peers and mentor would surely help me upskill for better and make this journey more thrilling.",
      college: "UPES",
      branch: "BTech, CSE",
      image: Deepanshi,
      passName: "Standard Pass",
    }, {
      name: "Yukti Kashyap",
      linkedin: "https://www.linkedin.com/in/yukti-kashyap-0a3297238",
      description: "'If u wish to move fast walk alone and if u wish to move far walk together' - I believe in her, hearing her thoughts made me realise that she is the one who can hold my hand even if I fall and say there is still a hope it's not too late - lift me up - guide me on the road which leads to my dream job I am really excited to learn and grow together in this program 🙂",
      college: "IGDTUW",
      branch: "BTech, CS & AI",
      image: Yukti,
      passName: "Standard Pass",
    }, {
      name: "Namita Bhatt",
      linkedin: "https://www.linkedin.com/in/namita-bhatt-82077424a",
      description: "I feel incredibly fortunate to have the opportunity to be a part of this cohort. I am eager to learn from Nishtha didi, and use her insights to navigate the challenges that lie ahead. As I embark on this journey, I am committed to working hard and maintaining a positive attitude.",
      college: "IGDTUW",
      branch: "BTech, ML & AI",
      image: Namita,
      passName: "Standard Pass",
    }, {
      name: "Gyapti Trivedi",
      linkedin: "https://www.linkedin.com/in/gyapti-trivedi",
      description: "Eagerness to learn something new and implement those things to develop new things and projects motivated me to join this batch. I am also confident that her guidance will help me to achieve my ultimate goal and be successful.",
      college: "IGDTUW",
      branch: "BTech, ECE - AI",
      image: Gyapti,
      passName: "Standard Pass",
    }, {
      name: "Navya Jain",
      linkedin: "https://www.linkedin.com/in/navya-jain-17b0aa250/",
      description: "My journey is way too similar to that of hers. And i got good vibes listening to her and ram bhaiya. And i m really feeling honoured to be a part of super 30. I hope i can make full out of this cohort.",
      college: "IGDTUW",
      branch: "BTech",
      image: Navya,
      passName: "Standard Pass",
    }, {
      name: "Anushka Shreyam",
      linkedin: "https://www.linkedin.com/in/anushka-shreyam-0b37191b8",
      description: "I firmly believe that the opportunity to learn from and collaborate with accomplished peers, as well as receive guidance from experienced instructor Nishtha, will allow me to reach my full potential. I am absolutely thrilled to have been selected for this prestigious batch, and I can hardly contain my excitement to embark on this journey of learning and growth with my fellow Rankers.",
      college: "IISER Bhopal",
      branch: "BS, Engineering Science",
      image: AnushkaShreyam,
      passName: "Early Bird Pass",
    }, {
      name: "Mahima",
      linkedin: "https://www.linkedin.com/in/mahima-✨-962b30229",
      description: "I needed a monitor who could guide and motivate me throughout this journey of learning, and as she's my senior I believe that she can do that perfectly.",
      college: "IGDTUW",
      branch: "BTech, IT",
      image: Mahima,
      passName: "Early Bird Pass",
    }, {
      name: "Anushka Singh",
      linkedin: "https://www.linkedin.com/in/anushka-singh-630042229/",
      description: "Mentorship from a successful professional like Ms. Nishtha is invaluable as it will give me a closer insight of her journey. This will be really helpful since I will be able to learn from her experience and thus would be able to understand what can I do to improve upon my technical skills so I can get a head start in my career.",
      college: "IGDTUW",
      branch: "BTech, CS & AI",
      image: AnushkaSingh,
      passName: "Early Bird Pass",
    }, {
      name: "Neha Tyagi",
      linkedin: "https://www.linkedin.com/in/neha-tyagi-coder",
      description: "I chose her for the amazing feedbacks I received from her previous students. I am impressed by her credentials and experience and feel that she is the best mentor to learn from. Extremely excited, grateful and looking forward to push myself to be the best!",
      college: "IGDTUW",
      branch: "BTech, ECE",
      image: Neha,
      passName: "Early Bird Pass",
    }, {
      name: "Diya Singla",
      linkedin: "https://www.linkedin.com/in/diya-singla-51905b212",
      description: "Nishtha Di is truly an inspiration to me! I am confident that her mentorship will provide me with invaluable guidance, insights, and direction to help me achieve my career aspirations. I am excited to be part of this batch.",
      college: "IGDTUW",
      branch: "BE, Artificial Intelligence",
      image: Diya,
      passName: "Early Bird Pass",
    }, {
      name: "Vanshika",
      linkedin: "https://www.linkedin.com/in/vanshika-81558126a",
      description: "Excited to be a mentee of Nishtha didi, she's an honest person therefore a trustworthy person and she has lots of other qualities which I want to acquire as I understand from her podcast.  It feels like a chance which I always wanted in my life and looking forward for this journey.",
      college: "IGDTUW",
      branch: "BTech, IT",
      image: Vanshika,
      passName: "Early Bird Pass",
    }, {
      name: "Pankaj Kumar",
      linkedin: "https://www.linkedin.com/in/pankajbengaluru",
      description: "Nishtha didi is an inspiration to me. Her motivational story about the journey of a genuine developer and her high achievements attracted me to get her mentorship. I have taken few courses like DSA self paced by Mr. Sandeep Jain but I want those skills & ways to learn from di.",
      college: "NMIT",
      branch: "MCA, CS",
      image: Pankaj,
      passName: "Early Bird Pass",
    }, {
      name: "Harshita Patel",
      linkedin: "https://www.linkedin.com/in/harshita-patel-9b81511b6",
      description: "I am super excited to learn and grow under the the guidance of Nishtha Di. Having a mentor can help in gaining Technical as well as soft skills and also provides a source of motivation and encouragement and this is the reason I chose Nishtha Di's batch.",
      college: "IGDTUW",
      branch: "BTech, IT",
      image: Harshita,
      passName: "Early Bird Pass",
    }, {
      name: "Anonymous",
      linkedin: "",
      description: "I have always been in need of a mentor who can guide me through the complex intricacies of the internship season and the tech industry. Having Nishtha di as a mentor will definitely help me conquer new frontiers.",
      college: "IGDTUW",
      branch: "BTech, CS & AI",
      image: Anonymous,
      passName: "Early Bird Pass",
    },
  ],
  dev: [
    {
      name: "Avinash Singh",
      linkedin: "https://www.linkedin.com/in/avinash-singh-software",
      description: "I am very excited. Having a successful senior from my college itself guiding me through the placement season was the prime motivation for me.",
      college: "VIT",
      branch: "BTech, CSE",
      image: Avinash,
      passName: "Early Bird Pass",
    }, {
      name: "Ashi Rathi",
      linkedin: "https://www.linkedin.com/in/ashirathi",
      description: "Thrilled to have found a course that aligns perfectly with the structure and mentorship I was seeking. Genuinely excited to be a part of this enriching learning community.",
      college: "VIT",
      branch: "BTech,IT",
      image: Ashi,
      passName: "Early Bird Pass",
    }, {
      name: "Satyam Raj",
      linkedin: "https://www.linkedin.com/in/satyam-raj-48808a206",
      description: "I found the syllabus precise and the one thing that I'm worried about is my consistency, I hope I'll get some help in that.",
      college: "VIT",
      branch: "BTech, Block Chain Technology",
      image: SatyamRaj,
      passName: "Early Bird Pass",
    },
  ]
}