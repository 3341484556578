// react
import React from 'react';

// css
import '../../static/styles/components/DET/ExplorationJourneyTicket.css';

// images
import BronzeTag1 from '../../static/images/Icons/BronzeTag1.png';
import BronzeTag2 from '../../static/images/Icons/BronzeTag2.svg';
import SilverTag1 from '../../static/images/Icons/SilverTag1.svg';
import SilverTag2 from '../../static/images/Icons/SilverTag2.png';
import GoldenTag1 from '../../static/images/Icons/GoldenTag1.svg';
import GoldenTag2 from '../../static/images/Icons/GoldenTag2.svg';
import CareerClub from '../../static/images/Icons/career_clubs.svg';
import Plane from '../../static/images/Icons/plane_in_button.png';

// pdf
import CurriculumPdf from '../../static/curriculum.pdf';

export default function ExplorationJourneyTicket() {
  return (
    <div className='ExplorationTicket'>
      <p className='ExplorationTicketMiniTitle'>Become 100% Coding and Interview Ready with</p>
      <div className='ExplorationTicketTitle'>
        <span className='orange-text'>Explorin's Live 2-month Placement Cohort </span>
      </div>
      <div className='ExplorationTicketIntroDiv'>
        <div className='ExplorationTicketIntro'>
          Based on the club system in IITs where successful students learn directly from top 1% seniors! Learn DSA concepts, Practice Daily Problems, Compete in Contests
        </div>
      </div>
      <div className='careerClub'>
        <img src={CareerClub} alt='club-img' />
      </div>
      <ul>
        <li>
          <div className='TicketDiv'>
            <div className='TicketType BzBg'>
              LEARN &nbsp;| <span className='small-head-type'>Sat-Sun</span>
            </div>
            <div className='TicketHorizontal'>
              <div className='TicketInfo Brnz '>
                <div className='TicketContent'>
                  <h2 className='InfoHeading Bz'>
                    Learn from IITian Coach on weekends
                  </h2>
                </div>
                <div className='TicketTags'>
                  <div className='TicketTagsCol'>
                    <img
                      className='TicketTagImage'
                      src={BronzeTag1}
                      alt='explore'></img>
                    <div>
                      <h4 className='TicketTagDetail'>
                        Live Classes
                      </h4>
                      <h5 className='TicketTagSubDetail'>Learn concepts in interactive live classes of 2 hours each</h5>
                    </div>
                  </div>
                  <div className='TicketTagsCol'>
                    <img
                      className='TicketTagImage'
                      src={BronzeTag2}
                      alt='session'></img>
                    <div>
                      <h4 className='TicketTagDetail'>
                        Resolve Doubts
                      </h4>
                      <h5 className='TicketTagSubDetail'>Ask any doubts in class to gain clarity of important concepts.</h5>
                    </div>
                  </div>
                </div>
                <div className='pricingTk showInMobileScreenOnly'>
                  <span className='curprice'>0 Rs</span>
                  <span className='striketxt'>
                    <strike>10,000 Rs</strike>
                  </span>
                  <br />
                  <span
                    className='seats'
                    style={{ color: '#005321' }}>
                    100% off.
                  </span>
                </div>
              </div>
              <a href='https://www.linkedin.com/in/jaglikemakkar' className='CoachLink' alt='linkedin' target='_blank' rel="noreferrer">
                <div className='TicketImage BzImg'></div>
              </a>
            </div>
          </div>
        </li>

        <li>
          <div className='TicketDiv'>
            <div className='TicketType SvBg'>
              PRACTICE &nbsp;| <span className='small-head-type'>Mon-Thu</span>
            </div>
            <div className='TicketHorizontal Silv'>
              <div className='TicketInfo'>
                <div className='TicketContent'>
                  <h2 className='InfoHeading Sv'>
                    Practice with your College Mentor on Weekdays
                  </h2>
                </div>
                <div className='TicketTags'>
                  <div className='TicketTagsCol'>
                    <img
                      className='TicketTagImage'
                      src={SilverTag1}
                      alt='explore'></img>
                    <div>
                      <h4 className='TicketTagDetail'>
                        Daily Practice Problems
                      </h4>
                      <h5 className='TicketTagSubDetail'>Solve practice problems handpicked by your placed seniors</h5>
                    </div>
                  </div>
                  <div className='TicketTagsCol'>
                    <img
                      className='TicketTagImage'
                      src={SilverTag2}
                      alt='session'></img>
                    <div>
                      <h4 className='TicketTagDetail'>
                        College Mentor Calls
                      </h4>
                      <h5 className='TicketTagSubDetail'>Get daily accountability and resolve doubts</h5>
                    </div>
                  </div>
                </div>
                <div className='pricingTk showInMobileScreenOnly'>
                  <span className='curprice'>0 Rs</span>
                  <span className='striketxt'>
                    <strike>5,000 Rs</strike>
                  </span>
                  <br />
                  <span
                    className='seats'
                    style={{ color: '#005321' }}>
                    100% off.
                  </span>
                </div>
              </div>
              <div className='TicketImage SvImg'></div>
            </div>
          </div>
        </li>

        <li>
          <div className='TicketDiv'>
            <div className='TicketType GdBg'>
              COMPETE &nbsp;| <span className='small-head-type'>Friday</span>
            </div>
            <div className='TicketHorizontal GdTicketBg'>
              <div className='TicketInfoGdn'>
                <div className='TicketContent'>
                  <h2 className='InfoHeadingGdn'>
                    Compete with your peers and Track progress
                  </h2>
                </div>
                <div className='TicketTags'>
                  <div className='TicketTagsCol'>
                    <img
                      className='TicketTagImage'
                      src={GoldenTag1}
                      alt='explore'></img>
                    <div>
                      <h4 className='TicketTagDetail'>
                        College Leaderboard
                      </h4>
                      <h5 className='TicketTagSubDetail'>Real time growth tracking and peer ranking</h5>
                    </div>
                  </div>
                  <div className='TicketTagsCol'>
                    <img
                      className='TicketTagImage'
                      src={GoldenTag2}
                      alt='session'></img>
                    <div>
                      <h4 className='TicketTagDetail'>
                        Cash Reward Ceremony
                      </h4>
                      <h5 className='TicketTagSubDetail'>Stand out and get a chance to earn cash prizes</h5>
                    </div>
                  </div>
                </div>
                <div className='pricingTk GdBg showInMobileScreenOnly'>
                  <span className='curprice'>25,000 Rs</span>
                  <span className='striketxt'>
                    <strike>50,000 Rs</strike>
                  </span>
                  <br />
                  <span
                    className='seats'
                    style={{ color: '#005321' }}>
                    50% off.
                  </span>
                </div>
              </div>
              <div className='TicketImage GdImg'></div>
            </div>
          </div>
        </li>
      </ul>
      <center>
        <a href={CurriculumPdf} download='explorin-master-curriculum' rel="noreferrer">
          <button className='button '>
            View detailed curriculum
            <img
              alt='plane-icon'
              src={Plane}
              className='PlaneInButton'
            />
          </button>
        </a>
      </center>
      {/* <div className='ExplorationTicketConclude'>
				Curious to know more about all the perks and rewards you will
				receive in D.E.T.?
				<br />
				Take a look at Trip Benefits to get all the details.
			</div>
			<Link to='/personality'>
				<button className='button centerbtn  GdBtn'>
					Explore all Benefits
				</button>
			</Link> */}
    </div>
  );
}
