// react
import React from 'react';

// react carousel
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

// images
import Plane from '../../static/images/Icons/plane_in_button.png';
import RamBansal from '../../static/images/Mentors_&_Team/RamBansal.png';
import AmanSingla from '../../static/images/Mentors_&_Team/AmanSingla.png';
import PrashantJindal from '../../static/images/Mentors_&_Team/PrashantJindal.png';

// urls
import { GOOGLE_REG_FORM } from '../../static/utils/applicationUrls';
import { StartJourneyPage, getIP, sendDataTOServer } from '../../static/utils/pageNames';
import isMobile from '../../static/utils/applicationUrls';

export default function MentorsOverviewMobile() {
  async function makeRequest() {
    const get_ip = await getIP()
    const data = { "ip": get_ip, "page": StartJourneyPage }
    console.log("Start Joruney", data)
    return await sendDataTOServer(data)
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const mentorCardsData = [
    {
      name: 'Aman Singla',
      designation: `founder Curelink, ex-Microsoft (CTC-2.4Cr)`,
      imageSrc: AmanSingla,
      team: 'IITR-2019',
    },
    {
      name: 'Prashant Jindal',
      designation: 'Co Founder, Explorin',
      imageSrc: PrashantJindal,
      team: `IITR-2017`,
    },
    {
      name: 'Ram Bansal',
      designation: 'Founder, Explorin',
      imageSrc: RamBansal,
      team: `IITR-2019`,
    },
  ];

  const mentorCards = mentorCardsData.map((val, index) => (
    <div className='explorin-empire-card' key={index}>
      <main className='designation'>
        <strong>{val.name}</strong>
        <section>{val.designation}</section>
      </main>
      <div className='profile'>
        <img src={val.imageSrc} alt='empire-profile-pic' />
        <main>{val.team}</main>
      </div>
    </div>
  ));

  return (
    <>
      <div className='empire-carousel-encloser-mobile'>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={true}
          responsive={responsive}
          ssr={!isMobile}
          deviceType={!isMobile ? 'desktop' : null}
          infinite={true}
          autoPlay={false}
          autoPlaySpeed={3000}
          additionalTransfrom={0}
          centerMode={false}
          keyBoardControl={true}
          customTransition='transform 300ms ease-in-out'
          transitionDuration={300}
          // transitionDuration={500}
          containerClass='carousel-container'
          removeArrowOnDeviceType={['tablet', 'mobile']}
          // deviceType={this.props.deviceType}
          dotListClass='custom-dot-list-style'>
          {mentorCards}
        </Carousel>
      </div>
      <a href={GOOGLE_REG_FORM} target='_blank' rel="noreferrer" className='pre-bk'>
        <button className='button sidebtn showInMobileScreenOnly' onClick={() => makeRequest()}>
          Apply now
          <img
            alt='plane-icon'
            src={Plane}
            className='PlaneInButton'
          />
        </button>
      </a>
    </>
  );
}
