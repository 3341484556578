// react imports
import React from 'react';

// css
import '../../static/styles/components/BasePage/Footer.css';

// images
import logo from '../../static/images/logo.png';

export default function Footer() {
	return (
		<>
			<footer className='page-footer'>
				<main className='footer-content-box'>
					<div className='about-explorin'>
						<div className='logo-foot'>
							<img
								alt='about'
								src={logo}
								className='footer-about-logo'
							/>
						</div>
						<p>
							Explorin came into existence with a vision to empower college
							students live their Dream Life. Our immediate mission is to
							provide students 360° personalised placement guidance by the
							top 1% mentors and coaches to help them bag great placements.
						</p>
						<p className='copyright'>© 2023 Explorin. All rights reserved</p>
					</div>
					<div className='explorin-other-footer-sections'>
						<div className='explorin-social col second-footer-row'>
							<h3>
								<span className='orange-text'>
									Contact Us
								</span>
							</h3>
							<div className='row socialm'>
								<div className='col'>
									<a href='https://www.youtube.com/channel/UCBRtnWdffqujfIcpuZlyWkg/videos' target='_blank' rel="noreferrer">
										<button href='//#endregion'>
											<i
												className='fa fa-youtube'
												aria-hidden='true'></i>
										</button>
									</a>
								</div>
								<div className='col'>
									<a href='https://www.facebook.com/explorin.io/' target='_blank' rel="noreferrer">
										<button href='//#endregion'>
											<i
												className='fa fa-facebook'
												aria-hidden='true'></i>
										</button>
									</a>
								</div>
							</div>
							<div className='row socialm'>
								<div className='col'>
									<a href='https://www.linkedin.com/company/explorin/' target='_blank' rel="noreferrer">
										<button href='//#endregion'>
											<i
												className='fa fa-linkedin'
												aria-hidden='true'></i>
										</button>
									</a>
								</div>
								<div className='col'>
									<a href='https://www.instagram.com/explorin.io/' target='_blank' rel="noreferrer">
										<button href='//#endregion'>
											<i
												className='fa fa-instagram'
												aria-hidden='true'></i>
										</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</main>
			</footer>
		</>
	);
}
