// react imports
import React from 'react';

// images
import DETImage from '../../static/images/Backgrounds/why_det_bg_web.png';
import Plane from '../../static/images/Icons/plane_in_button.png';
import Nanodegree from '../../static/images/Icons/nanodegree.svg';

// css
import '../../static/styles/components/BasePage/WhyDetSection.css';

// urls
import {
  GOOGLE_REG_FORM
} from '../../static/utils/applicationUrls';
export default function WhyDetSection({ mentorData }) {

  return (
    <div className='WhyDetBase'>
      <div className='democirclediv'>
        <div className='democircle'></div>
      </div>
      <div className='temp'>
        <img
          alt='why-det-bg'
          className='WhyDetBackground'
          src={DETImage}
        />
      </div>
      <div className='WhyDetData'>
        <div className='WhyDetText'>
          <h1 className='WhyDetTitle'>
            Along with a college degree,<br /> earn a <span className='orange-text'> Placement Training Certificate </span>
          </h1>
          <div className='WhyDetIntroDiv'>
            <p className='WhyDetIntro'>
              Industry Verified Training Certificate to ensure guaranteed Career Success in technical roles
            </p>
          </div>
          <a href={mentorData?.formLink || GOOGLE_REG_FORM} target='_blank' rel="noreferrer">
            <button className='button'>
              Apply now
              <img
                alt='plane-icon'
                src={Plane}
                className='PlaneInButton'
              />
            </button>
          </a>
        </div>
        <div className='WhyDetImages'>
          <img src={Nanodegree} alt='nanodegree' />
          <a href={mentorData?.formLink || GOOGLE_REG_FORM} target='_blank' rel="noreferrer">
            <button className='button showInMobileScreenOnly'>
              Apply now
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
