// react imports
import React from "react";
import Faq from "react-faq-component";

//css
import '../../static/styles/components/BasePage/FAQs.css';

//images
import Plane from '../../static/images/Icons/plane_in_button.png';

//urls
import { GOOGLE_REG_FORM, GOOGLE_ANALYST_REG_FORM } from '../../static/utils/applicationUrls';

const data = {
  rows: [
    {
      title: "1. What is the duration of the program?",
      content: `The program officially is 8 weeks long. An additional training of 4 weeks will be provided for program toppers.`,
    },
    {
      title: " 2. Is this program only for computer science students?",
      content: `No, the program is open to students from all majors who are interested in preparing for coding interviews and placement opportunities.`,
    },
    {
      title: "3. Do we have any program for Analyst positions?",
      content: `Coming soon! Apply <a href='${GOOGLE_ANALYST_REG_FORM}' style="background: #F37021;padding: 3px 13px;border-radius:8px;" target='_blank'>here</a> for early access. `,
    },
    {
      title: "4. Who are the mentors and what is their experience?",
      content: `The mentors are top-placed seniors who have already gone through the placement process and have landed great jobs. They have first-hand experience of what it takes to succeed in coding interviews and will share their knowledge with you.`,
    },
    {
      title: "5. What if I miss a session?",
      content: `We understand that students have busy schedules, so we will record all sessions and make them available to you for later viewing.`,
    },
    {
      title: "6. Will there be any assignments or projects during the program?",
      content: `Yes, there will be daily coding assignments and projects to help you apply the concepts you learn from the coach during the live sessions on weekends.`
    },
    {
      title: "7. What programming languages will be used in the program?",
      content: `The coach will be majorly using C++ and Python during teaching but the main focus would be on logic building and hence the program would be programming language insensitive.`
    }
  ],
};

export default function FAQs({mentorData}) {
  return (
    <div className='faqs'>
      <h1 className='faqs-title orange-text'>
        Have more questions?
      </h1>
      <div className='question-div'>
        <Faq
          data={data}
        />
      </div>
      <br /><br />
      <a href={mentorData?.formLink || GOOGLE_REG_FORM} className="pre-bk" target='_blank' rel="noreferrer">
        <button className='button sidebtn'>
          Apply now
          <img
            alt='plane-icon'
            src={Plane}
            className='PlaneInButton'
          />
        </button>
      </a>
    </div>
  );
}
