// react imports
import React, { Component } from 'react';

// images
import HomePageImage from '../../static/images/Backgrounds/home_page_bg_web.png';
import Plane from '../../static/images/Icons/plane_in_button.png';

//css
import '../../static/styles/components/BasePage/HomeSection.css';

export default class HomeSection extends Component {
  render() {
    return (
      <>
        {this.props.mentorData.trailerLink ?
          <div className='HomePage batch-hm'>
            <div className='temp'>
              <img
                alt='home-page'
                className='HomeBackground hide'
                src={HomePageImage}
              />
            </div>
            <div className='HomeTextWeb mentorSpecific-HomeTextWeb'>
              <h1 className='HomeTitle mentorHomeTitle'>
                Get 100% Placement ready with me<br />
                <span className="orange-text"> Join the Super 30 now! </span>
              </h1>
              <h4 className='HomeTitleIntro'>
                <span className='name'>{this.props.mentorData.name}</span>
                <span className='company'>
                  {this.props.mentorData.about1}<br />
                  {this.props.mentorData.about2}
                </span>
              </h4>
              {/* <img className='logo' src={this.props.mentorData.logo} alt='company' /> */}
              <div className='homeButBox'>
                <a
                  href={this.props.mentorData?.formLink}
                  target='_blank' rel="noreferrer">
                  <button className='btnhomeR' onClick={this.makeRequest}>
                    <img
                      alt='Plane'
                      src={Plane}
                      className='PlaneInButtonR'
                    />
                    Apply now
                  </button>
                </a>
                {this.props.mentorData?.batch &&
                  <a href={`/${this.props?.mentorData?.lowerName}/mybatch`}>
                    <button className='btnhomeL' onClick={this.makeRequest}>
                      My Super 30
                      <img
                        alt='Plane'
                        src={Plane}
                        className='PlaneInButton'
                      />
                    </button>
                  </a>
                }
              </div>
              <br />
            </div>
            <div className='HomeDreamer mentorSpecific-HomeDreamer'>
              <iframe src={`${this.props?.mentorData?.trailerLink}?autoplay=1`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              {this.props?.mentorData?.lowerName !== "abhishek" && this.props?.mentorData?.lowerName !== "sahil" &&
                <a className='entire-journey' href={this.props?.mentorData?.lowerName === "nishtha" || this.props?.mentorData?.lowerName === "dev" ? `/${this.props?.mentorData?.lowerName}/mystory` : `/${this.props?.mentorData?.lowerName}/codefest`}>watch my entire journey &gt;</a>}
            </div>
            <div className='HomeTextMob mentorSpecific-HomeTextMob'>
              <h1 className='HomeTitle'>
                Get 100% Placement ready with me<br />
                <span className="orange-text"> Join the Super 30 now! </span>
              </h1>
              <div className='iframe-mob-div'>
                <iframe src={this.props?.mentorData?.trailerLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                {this.props?.mentorData?.lowerName !== "abhishek" && this.props?.mentorData?.lowerName !== "sahil" &&
                  <a className='entire-journey' href={this.props?.mentorData?.lowerName === "nishtha" || this.props?.mentorData?.lowerName === "dev" ? `/${this.props?.mentorData?.lowerName}/mystory` : `/${this.props?.mentorData?.lowerName}/codefest`}>watch my entire journey &gt;</a>}
              </div>
              <h4 className='HomeTitleIntro'>
                <span className='name'>{this.props.mentorData.name}</span>
                <span className='company'>
                  {this.props.mentorData.about1}<br />
                  {this.props.mentorData.about2}
                </span>
              </h4>
              <div className='homeButBox'>
                <a
                  href={this.props.mentorData?.formLink}
                  target='_blank' rel="noreferrer">
                  <button className='btnhomeR' onClick={this.makeRequest}>
                    Apply now
                    <img
                      alt='Plane'
                      src={Plane}
                      className='PlaneInButton'
                    />
                  </button>
                </a>
                {this.props.mentorData?.batch &&
                  <a href={`/${this.props?.mentorData?.lowerName}/mybatch`}>
                    <button className='btnhomeL' onClick={this.makeRequest}>
                      My Super 30
                      <img
                        alt='Plane'
                        src={Plane}
                        className='PlaneInButton'
                      />
                    </button>
                  </a>}
              </div>
            </div>
          </div> : <div className='HomePage'>
            <div className='temp'>
              <img
                alt='home-page'
                className='HomeBackground hide'
                src={HomePageImage}
              />
            </div>
            <div className='HomeTextWeb mentorSpecific-HomeTextWeb'>
              <h5><span className="orange-text">Become 100% Coding & Interview Ready with 2-month Placement Cohort </span></h5>
              <h1 className='HomeTitle'>
                Get 0-1 Placement Training with
                <span className="orange-text"> {this.props.mentorData?.firstName} </span> <span className='upcominTag'>Placed at {this.props.mentorData?.company}
                </span>
              </h1>
              <h4 className='HomeTitleIntro'>
                {this.props.mentorData?.headSubTitle}< br /><br />
              </h4>
              <div className='homeButBox'>
                <a
                  href={this.props.mentorData?.formLink}
                  target='_blank' rel="noreferrer">
                  <button className='btnhomeR' onClick={this.makeRequest}>
                    <img
                      alt='Plane'
                      src={Plane}
                      className='PlaneInButtonR'
                    />
                    Apply now
                  </button>
                </a>
                <a href={this.props?.mentorData?.lowerName === "nishtha" || this.props?.mentorData?.lowerName === "dev" ? `/${this.props?.mentorData?.lowerName}/mystory` : `/${this.props?.mentorData?.lowerName}/codefest`}>
                  <button className='btnhomeL' onClick={this.makeRequest}>
                    {this.props?.mentorData?.lowerName === "nishtha" || this.props?.mentorData?.lowerName === "dev" ?
                      "My Placement Story" : "My CodeFest"}
                    <img
                      alt='Plane'
                      src={Plane}
                      className='PlaneInButton'
                    />
                  </button>
                </a>
              </div>
              <br />
              {/* <h5><span className="orange-text">** Get upto INR 5000 scholarship</span></h5> */}

            </div>
            <div className='HomeDreamer mentorSpecific-HomeDreamer'>
              <img src={this.props.mentorData?.image} className='hideInMobileScreenOnly' alt='dreamers' />
            </div>
            <div className='HomeTextMob mentorSpecific-HomeTextMob'>
              <h5><span className="orange-text">Become 100% Coding & Interview Ready with 2-month Placement Cohort </span></h5>
              <h1 className='HomeTitle'>
                Get 0-1 Placement Training with
                <span className="orange-text"> {this.props.mentorData?.firstName} </span><br /> <span className='upcominTag'>Placed at {this.props.mentorData?.company}
                </span>
              </h1>
              <h4 className='HomeTitleIntro'>
                {this.props.mentorData?.headSubTitle}< br /><br />
              </h4>
              <img
                className='ideate showInMobileScreenOnly'
                src={this.props.mentorData?.image}
                alt='boxes'></img>
              <div className='homeButBox'>
                <a
                  href={this.props.mentorData?.formLink}
                  target='_blank' rel="noreferrer">
                  <button className='btnhomeR' onClick={this.makeRequest}>
                    Apply now
                    <img
                      alt='Plane'
                      src={Plane}
                      className='PlaneInButton'
                    />
                  </button>
                </a>
                <a href={`/${this.props?.mentorData?.lowerName}/mystory`}>
                  <button className='btnhomeL' onClick={this.makeRequest}>
                    {this.props?.mentorData?.lowerName === "nishtha" || this.props?.mentorData?.lowerName === "dev" ?
                      "My Placement Story" : "My CodeFest"}
                    <img
                      alt='Plane'
                      src={Plane}
                      className='PlaneInButton'
                    />
                  </button>
                </a>
              </div>
            </div>
          </div>}
      </>
    );
  }
}