// react
import React from 'react';

// images
import Plane from '../../static/images/Icons/plane_in_button.png';
import Background from '../../static/images/Backgrounds/start-journey-bg.svg';
import JourneySteps from '../../static/images/Icons/journey-steps.svg';
import JourneyStepsMentor from '../../static/images/Icons/journey-steps.svg';

// css
import '../../static/styles/components/BasePage/StartJourney.css';

// urls
import { GOOGLE_REG_FORM } from '../../static/utils/applicationUrls';
export default function StartJourney({ mentorData }) {

  return (
    <>
      <div className='journey-page'>
        <div className='journey-head'>
          <div className='journey-heading'>
            Ready to
            <span className='orange-text'> Start </span>
            your Journey ?
          </div>
          <a href={mentorData ? mentorData?.formLink : GOOGLE_REG_FORM} target='_blank' rel="noreferrer">
            <button className='button sidebtn'>
              Apply now
              <img
                alt='plane-icon'
                src={Plane}
                className='PlaneInButton'
              />
            </button>
          </a>
        </div>
        <img src={mentorData ? JourneyStepsMentor : JourneySteps} className='journey-steps' alt='steps' />
        <img
          src={Background}
          className='journey-bg '
          alt='experience'
        />
      </div>
    </>
  );
}
