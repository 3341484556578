import Dev_5 from '../images/MentorSpecific/sessions/dev_5.svg';
import Dev_6 from '../images/MentorSpecific/sessions/dev_6.svg';
import Leaderboard from '../images/MentorSpecific/codefestLeaderboard.svg';

export const SESSIONS = {
  dev: [
    {
      isCompleted: true,
      link: 'https://www.youtube.com/embed/AT49-PvXi2g',
    },
    {
      isCompleted: true,
      link: 'https://www.youtube.com/embed/7qyEV_W_2CQ',
    },
    {
      isCompleted: true,
      link: 'https://www.youtube.com/embed/wcUs20aJiSY',
    },
    {
      isCompleted: true,
      link: 'https://www.youtube.com/embed/Z4yjqsxl2OA',
    },
    {
      isCompleted: false,
      image: Dev_5,
    },
    {
      isCompleted: false,
      image: Dev_6,
    }
  ],
  // akash: [
  //   {
  //     link: 'https://www.youtube.com/embed/V-sA0QhwuzM',
  //     highlights: `Key Highlights: \n
  //     1. Criteria to Apply for Top companies
  //     2. Akash's Placement Resume
  //     3. Resume Building`,
  //     notes: '',
  //   },
  // ]
  abhishek: [
    {
      isCompleted: true,
      link: 'https://www.youtube.com/embed/nd1ruaAU0Uc',
    },
    {
      isCompleted: false,
      contest: 'https://www.hackerrank.com/contests/explorin-super-30-2/leaderboard',
      image: Leaderboard,
    },
    {
      isCompleted: true,
      link: 'https://www.youtube.com/embed/fNeej1EN5gU',
    },
  ],
  sahil: [
    {
      isCompleted: true,
      link: 'https://www.youtube.com/embed/ZsXTO0c1IBc',
    },
    {
      isCompleted: false,
      contest: 'https://www.hackerrank.com/contests/explorin-super-30-3/leaderboard',
      image: Leaderboard,
    },
    {
      isCompleted: true,
      link: 'https://www.youtube.com/embed/Na3mA-EfB0s',
    },
  ],
}