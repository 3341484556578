import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';

//images
import logo from '../../static/images/logo.png';

//css
import '../../static/styles/components/BasePage/NavBarMob.css';

//urls
import { GOOGLE_REG_FORM } from '../../static/utils/applicationUrls';

export default class NavBar_mob extends Component {

  render() {
    return (
      <div className='mobile'>
        <nav className='navbar navbar-expand-lg navbar-dark'>
          <Link to='/'>
            <img src={logo} width='170' alt='pic' />
          </Link>
          {window.location.pathname.includes('/mystory') || window.location.pathname.includes('/codefest') ? <>
            {this.props.mentorData.podcastLink ?
              <a
                href={this.props?.mentorData?.formLink}
                className='WebNavbar__menu--items mobnav-content twenem'
                target='_blank' rel="noreferrer">
                <button className='btn--ticket--golden'>
                  Apply now
                </button>
              </a> :
              <a
                href={this.props?.mentorData?.storyFormLink}
                className='WebNavbar__menu--items mobnav-content twenem'
                target='_blank' rel="noreferrer">
                <button className='btn--ticket--golden'>
                  Book Seat
                </button>
              </a>
            } </>
            : <a
              href={this.props?.mentorData?.formLink || GOOGLE_REG_FORM}
              className='WebNavbar__menu--items mobnav-content twenem'
              target='_blank' rel="noreferrer">
              <button className='btn--ticket--golden'>
                Apply now
              </button>
            </a>}
          {window.location.pathname !== '/' &&
            <>
              <button
                className='navbar-toggler'
                type='button'
                data-toggle='collapse'
                data-target='#navbarTogglerDemo03'
                aria-controls='navbarTogglerDemo03'
                aria-expanded='false'
                aria-label='Toggle navigation'
                style={{ border: 'none' }}>
                <svg
                  width='29'
                  height='20'
                  viewBox='0 0 29 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M1 1.5H27.25'
                    stroke='#fff'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M1 10.25H27.25'
                    stroke='#fff'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M1 19H27.25'
                    stroke='#fff'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </button>
              <div
                className='collapse navbar-collapse'
                id='navbarTogglerDemo03'>
                <ul className='navbar-nav ml-auto mt-2 mt-lg-0'>
                  {window.location.pathname.includes('/mystory') || window.location.pathname.includes('/codefest') ?
                    <Link className='mobnav-content' to={`/${this.props?.mentorData?.lowerName}`}>
                      <div className='WebNavbar__menu--items'>
                        My Profile
                      </div>
                    </Link>
                    : <Link className='mobnav-content' to={this.props?.mentorData?.lowerName === "nishtha" || this.props?.mentorData?.lowerName === "dev" ? `/${this.props?.mentorData?.lowerName}/mystory` : `/${this.props?.mentorData?.lowerName}/codefest`}>
                      <div className='WebNavbar__menu--items'>
                        {this.props?.mentorData?.lowerName === "nishtha" || this.props?.mentorData?.lowerName === "dev" ?
                          "My Placement Story" : this.props?.mentorData?.lowerName !== "abhishek" && this.props?.mentorData?.lowerName !== "jatin" && this.props?.mentorData?.lowerName !== "sahil" && "My CodeFest"}
                      </div>
                    </Link>}
                  {/* <Link className='mobnav-content' to={`/${this.props?.mentorData?.lowerName}/mybatch`}>
                    <div className='WebNavbar__menu--items'>
                      My Batch
                    </div>
                  </Link> */}
                  <Link className='mobnav-content' to='/'>
                    <div className='WebNavbar__menu--items'>
                      About Cohort
                    </div>
                  </Link>
                </ul>
              </div>
            </>
          }
        </nav>
      </div>
    );
  }
}
