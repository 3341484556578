import React from "react";

//images 
import Sample from '../../static/images/MentorSpecific/attendeeSample.svg';
import Plane from '../../static/images/Icons/plane_in_button.png';

export default function PreviousWebinars({ mentorData, hideSub }) {

  return (
    <>
      {mentorData.visionVideo &&
        <>
          {!hideSub &&
            <div className="meet-me-live previous-dreamers">
              {mentorData.lowerName === "nishtha" ?
                <h2>{mentorData.firstName}'s <span className="orange-text">SUPER-30</span> vision</h2>
                :
                <h2>7 Day Vellore Placement<span className="orange-text"> Series </span></h2>}
              <h6>A series of episodes to help you crack the best offers coming to your campus.</h6>
              <div className="session-box">
                <div className="frame">
                  <iframe src={mentorData.visionVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="frame-description">
                  {mentorData.lowerName === "nishtha" ?
                    <>
                      <img src={Sample} alt="sample" />
                      <h5> "To kickstart the series, I and Explorin's Founder Ram talked about the vision behind my Super 30 Batch. We also shared the 3 qualities I am looking for in my mentees."</h5>
                      <a href={mentorData.formLink} target='_blank' rel="noreferrer">
                        <button className='button sidebtn notes-btn'>
                          Apply now
                        </button>
                      </a>
                    </> :
                    <>
                      <h5 className="vision-sub-head">Join Me Live {mentorData.liveTime} </h5><br />
                      <h5>In this series, you'll learn: <br /><br />
                        1. Tips and tricks to crack your dream companies <br />
                        2. Problem-solving skills and creating a winning profile <br />
                        3. Acing the coding interview</h5>
                      <a href={mentorData.whatsapp} target='_blank' rel="noreferrer">
                        <button className='button sidebtn notes-btn'>
                          Join now
                        </button>
                      </a>
                    </>}
                </div>
              </div>
              {mentorData?.additionalVideo &&
                <>
                  {mentorData.lowerName === "nishtha" ?
                    <h2 className="head-nish">Technical Interview 101</h2> :
                    <h2>{mentorData.firstName}'s <span className="orange-text">SUPER-30</span> vision</h2>}
                  <div className="session-box">
                    <div className="frame">
                      <iframe src={mentorData.additionalVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className="frame-description">
                      {mentorData?.lowerName === "nishtha" ?
                        <h5>For the upcoming IGDTUW Internship & Placement season, I've shared the basics of Technical Interviews, How to prepare for them and stay consistent over a period of 3 months!</h5>
                        :
                        <h5>Those who crack Super Dream Companies have an advantage. They get to know all the strategies from their placed seniors in advance! <br />Dev is bringing that unfair advantage to his Super 30 batch</h5>}
                      <a href={mentorData.formLink} target='_blank' rel="noreferrer">
                        <button className='button sidebtn notes-btn'>
                          Apply now
                        </button>
                      </a>
                    </div>
                  </div>
                </>
              }
            </div>
          }
          {mentorData.previousVideo &&
            <div className="horizontal-vids">
              {hideSub ? mentorData.lowerName === "dev" ? <center>
                <div class="ExplorationTicketTitle">7 Day Vellore <span class="orange-text">Placement Series</span></div>
              </center> : <center>
                <div class="ExplorationTicketTitle">CodeFest <span class="orange-text">Highlights</span></div>
              </center> :
                <div class="ExplorationTicketTitle">Free + Live Sessions for <span class="orange-text">my College Juniors</span></div>}
              <div className="all-sessions">
                {mentorData.previousVideo.map((vid, i) => {
                  return (
                    <>
                      {hideSub ? i < 3 &&
                        (vid.isCompleted ?
                          <iframe src={vid.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          : <img src={vid.image} alt="session" onClick={() => { window.location.href = vid.contest; }} />
                        ) :
                        vid.isCompleted ?
                          <iframe src={vid.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          :
                          <img src={vid.image} alt="session" />
                      }
                    </>)
                })}
              </div>
              <div className={!hideSub ? "bottom-btn" : "bottom-btn spcng"}>

                {hideSub ? mentorData?.lowerName !== "abhishek" && mentorData?.lowerName !== "sahil" && <a href={mentorData?.lowerName === "nishtha" || mentorData?.lowerName === "dev" ? `/${mentorData?.lowerName}/mystory` : `/${mentorData?.lowerName}/codefest`} rel="noreferrer">
                  <button className='button sidebtn'>
                    Watch now
                    <img
                      alt='plane-icon'
                      src={Plane}
                      className='PlaneInButton'
                    />
                  </button>
                </a> :
                  <a href={mentorData.whatsapp} target='_blank' rel="noreferrer">
                    <button className='button sidebtn'>
                      Join now
                      <img
                        alt='plane-icon'
                        src={Plane}
                        className='PlaneInButton'
                      />
                    </button>
                  </a>}
              </div>
            </div>}

        </>
        // <div className="meet-me-live previous-dreamers">
        //   <h2>MY Placement & Intern <br /><span className="orange-text">Live Series</span></h2>
        //   <h6>A series of episodes to help you crack the best offers coming to your campus.</h6>
        //   {mentorData.previousVideo.map((data) => {
        //     return <div className="session-box">
        //       <div className="frame">
        //         <iframe src={data.link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        //       </div>
        //       <div className="frame-description">
        //         <h5>{data.highlights}</h5>
        //         <a href={data.notes} target='_blank' rel="noreferrer">
        //           <button className='button sidebtn notes-btn'>
        //             Meeting notes
        //           </button>
        //         </a>
        //       </div>
        //     </div>
        //   })}

        // </div>
      }

    </>
  );
}