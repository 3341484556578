// react imports
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Redirect } from 'react-router';

// boostrap js
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

// local imports
import BasePage from './containers/BasePage';
import MentorPage from './containers/MentorPage';
import MentorStory from './containers/MentorStory';
import MentorBatch from './containers/MentorBatch';

const DOMElement = document.getElementById('root');
render(
  <Router>
    <Switch>
      <Route exact path='/'>
        <Suspense
          fallback={
            <div className='blank-screen-culprit' id='loader'></div>
          }>
          <BasePage />
        </Suspense>
      </Route>
      <Route exact path='/:mentorId'>
        <Suspense
          fallback={
            <div className='blank-screen-culprit' id='loader'></div>
          }>
          <MentorPage />
        </Suspense>
      </Route>
      <Route exact path='/:mentorId/cohort'>
        <Suspense
          fallback={
            <div className='blank-screen-culprit' id='loader'></div>
          }>
          <BasePage />
        </Suspense>
      </Route>
      <Route exact path='/:mentorId/codefest'>
        <Suspense
          fallback={
            <div className='blank-screen-culprit' id='loader'></div>
          }>
          <MentorStory />
        </Suspense>
      </Route>
      <Route exact path='/:mentorId/mystory'>
        <Suspense
          fallback={
            <div className='blank-screen-culprit' id='loader'></div>
          }>
          <MentorStory />
        </Suspense>
      </Route>
      <Route exact path='/:mentorId/mybatch'>
        <Suspense
          fallback={
            <div className='blank-screen-culprit' id='loader'></div>
          }>
          <MentorBatch />
        </Suspense>
      </Route>
      <Route path='*'>
        <Redirect to='/' />
      </Route>
    </Switch>
  </Router>
  ,
  DOMElement
);
