// react imports
import React from "react";
import { useParams } from "react-router";

// css
import '../static/styles/common/landing.css';
import '../static/styles/common/CommonElements.css';
import '../static/styles/common/MediaQue.css';
import '../static/styles/common/MentorSpecific.css';

// local imports
import NavBarWeb from '../components/BasePage/NavBarWeb';
import NavBarMob from '../components/BasePage/NavBarMob';
import Chat from '../components/BasePage/ChatWithUs';
import HomeSectionMyStory from '../components/MentorPage/HomeSectionMyStory';
import PreviousWebinars from "../components/MentorPage/previousWebinars";
import MeetMeLive from "../components/MentorPage/meetMeLive";
import WhatItIs from "../components/MentorPage/WhatItIs";
import WhoIsThisFor from "../components/MentorPage/WhoIsThisFor";
import CodeFestCertificate from "../components/MentorPage/CodeFestCertificate";
import WhatWillYouGet from "../components/MentorPage/WhatWillYouGet";
import FAQs from "../components/MentorPage/FAQs";
import Footer from "../components/BasePage/Footer";

// utils
import { MENTORS } from '../static/utils/mentors';

export default function MentorStory() {
  const { mentorId } = useParams();
  const mentorData = MENTORS[mentorId];

  return (
    <>
      {mentorData ?
        <div className='basesection storypage'>
          <NavBarWeb mentorData={mentorData} />
          <NavBarMob mentorData={mentorData} />
          <Chat />
          {(mentorData.lowerName !== 'abhishek' && mentorData.lowerName !== 'sahil') &&
            <PreviousWebinars mentorData={mentorData} />}
          <HomeSectionMyStory mentorData={mentorData} />
          <MeetMeLive mentorData={mentorData} />
          {mentorData.lowerName !== "nishtha" && mentorData.lowerName !== "dev" && mentorData.lowerName !== "jatin" && mentorData.lowerName !== "sahil" ?
            <>
              <WhatItIs />
              <WhoIsThisFor mentorData={mentorData} />
              <WhatWillYouGet mentorData={mentorData} />
              <CodeFestCertificate />
              <FAQs mentorData={mentorData} />
              <Footer />
            </> : ""}
        </div>
        :
        (
          window.location.replace("https://placement.explorin.io")
        )}
    </>
  );
}
