// react
import React from 'react';

// css
import '../../static/styles/components/DET/Prerequisites.css';

// images
import NoTechnical from '../../static/images/Icons/det-no-technical-icon.svg';
import Willingness from '../../static/images/Icons/det-willingness-icon.svg';
import Desire from '../../static/images/Icons/det-desire-icon.svg';
import Freshers from '../../static/images/Icons/det-freshers-icon.svg';

export default function WhoIsThisFor({ mentorData }) {
	return (
		<main className='prereq-section who-is-this-for'>
			<h2>
				<span className='orange-text'>Who </span>is this for ?
			</h2>
			<p className="heading-ans">
				Students aiming to crack top On Campus Placements
			</p>
			<section className='prereq-icons'>
				<div className='prereq-tc-r'>
					<div className='prereq-text-containers'>
						<img alt='no-technical' src={NoTechnical} />
						<p>
							Students of <br className='showInMobileScreenOnly' /> {mentorData.college} junior
						</p>
					</div>
					<div className='prereq-text-containers'>
						<img alt='freshers' src={Freshers} />
						<p>
							Preferred Year of Study is 2nd, 3rd and 4th
						</p>
					</div>
				</div>
				<div className='prereq-tc-r'>
					<div className='prereq-text-containers'>
						<img alt='willingness' src={Willingness} />
						<p>
							Preparing for on-campus opportunities
						</p>
					</div>
					<div className='prereq-text-containers'>
						<img alt='desire' src={Desire} />
						<p>
							Having passion for problem solving
						</p>
					</div>
				</div>
			</section>
		</main>
	);
}