// react imports
import React, { Component } from 'react';

// images
import Plane from '../../static/images/Icons/plane_in_button.png';

//css
import '../../static/styles/components/BasePage/HomeSection.css';

export default class HomeSectionMyStory extends Component {

  render() {
    return (
      <div className='HomePage MentorStory'>
        {!this.props.mentorData.podcastLink ?
          <div className='without-embed'>
            <div className='HomeTextWeb mentorSpecific-HomeTextWeb'>
              <h1 className='HomeTitle'>{this.props.mentorData.lowerName === "jatin" || this.props.mentorData.lowerName === "sahil" ? <>Why My <span className="orange-text">Super 30</span></> : <>My Super 30 <span className="orange-text">CodeFest</span></>}
                <span className='upcominTag'>Free Session
                  {/* <svg height="12" width="12" className="blinking">
                    <circle cx="5" cy="5" r="5" fill="#F37021" />
                  </svg> */}
                </span>
              </h1>
              <p className='collab'>{this.props.mentorData?.collab}</p>
              <h4 className='HomeTitleIntro'>
                {this.props.mentorData.lowerName === "nishtha" || this.props.mentorData.lowerName === "dev" ? <>{this.props.mentorData.college} Internship and placement season is coming up soon... I've been at the exact same place preparing for on-campus opportunities in SDE role. So, I'm going to tell my entire preparation story live in this podcast!</> :
                  this.props.mentorData.storyText}
                <br />
                {this.props.mentorData.lowerName === "nishtha" || this.props.mentorData.lowerName === "dev" ?
                  "In this Grand Event I'm going to share my true story & placement strategy with driven juniors aiming for super dream companies this year!" :
                  <><br />
                    In this session we'll discuss:
                    <ul>
                      <li>The vision behind my Super 30 Placement Batch </li>
                      <li>How my Super 30 would help you crack your dream placement </li>
                      <li>Answer to all your burning questions & concerns regarding my Super 30 </li></ul>
                  </>}
              </h4>
              <div className='homeButBox'>
                <a
                  href={this.props.mentorData?.storyFormLink}
                  target='_blank' rel="noreferrer">
                  <button className='btnhomeR'>
                    <img
                      alt='Plane'
                      src={Plane}
                      className='PlaneInButtonR'
                    />
                    Book your seat
                  </button>
                </a>
              </div>
            </div>
            <div className='HomeDreamer mentorSpecific-HomeDreamer'>
              <img src={this.props.mentorData?.myStoryImg} className='hideInMobileScreenOnly' alt='dreamers' />
            </div>
          </div>
          :
          <div className='embed'>
            <div className='HomeTextWeb mentorSpecific-HomeTextWeb'>
              <h1 className='HomeTitle'>{this.props.mentorData.firstName}'s<span className="orange-text"> {this.props.mentorData.company} Placement Story</span></h1>
              <h4 className='HomeTitleIntro'>
                I've shared my entire placement story in the podcast which includes my preparation strategies as well as my interview experience! <br /><br />
                Ps. I've launched my Super 30 batch, whom I'll get 100% placement ready in the next 3 months!
              </h4>
              <div className='homeButBox'>
                <a
                  href={this.props.mentorData?.formLink}
                  target='_blank' rel="noreferrer">
                  <button className='btnhomeR'>
                    <img
                      alt='Plane'
                      src={Plane}
                      className='PlaneInButtonR'
                    />
                    Apply Now
                  </button>
                </a>
              </div>
            </div>
            <div className='HomeDreamer mentorSpecific-HomeDreamer'>
              <iframe src={this.props.mentorData.podcastLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
          </div>}

        {!this.props.mentorData.podcastLink ?
          <div className='HomeTextMob mentorSpecific-HomeTextMob'>
            <br />
            <h1 className='HomeTitle'>{this.props.mentorData.lowerName === "jatin" || this.props.mentorData.lowerName === "sahil" ? <>Why My <span className="orange-text">Super 30</span></> : <>My Super 30 <span className="orange-text">CodeFest</span></>}</h1>
            <p className='collab'>{this.props.mentorData?.collab}</p>
            <h4 className='HomeTitleIntro'>
              {this.props.mentorData.lowerName === "nishtha" || this.props.mentorData.lowerName === "dev" ? <>{this.props.mentorData.college} Internship and placement season is coming up soon... I've been at the exact same place preparing for on-campus opportunities in SDE role. So, I'm going to tell my entire preparation story live in this podcast!</> :
                this.props.mentorData.storyText}
              <br />
              {this.props.mentorData.lowerName === "nishtha" || this.props.mentorData.lowerName === "dev" ?
                "In this Grand Event I'm going to share my true story & placement strategy with driven juniors aiming for super dream companies this year!" :
                <><br />
                  In this session we'll discuss:
                  <ul>
                    <li>The vision behind my Super 30 Placement Batch </li>
                    <li>How my Super 30 would help you crack your dream placement </li>
                    <li>Answer to all your burning questions & concerns regarding my Super 30 </li></ul>
                </>
              }
            </h4>
            <img
              className='ideate showInMobileScreenOnly'
              src={this.props.mentorData?.myStoryImg}
              alt='boxes'></img>
            <div className='homeButBox'>
              <a
                href={this.props.mentorData?.storyFormLink}
                target='_blank' rel="noreferrer">
                <button className='btnhomeR'>
                  Book your seat
                  <img
                    alt='Plane'
                    src={Plane}
                    className='PlaneInButton'
                  />
                </button>
              </a>
            </div>
          </div> :
          <div className='HomeTextMob mentorSpecific-HomeTextMob'><br />
            <h1 className='HomeTitle'>{this.props.mentorData.firstName}'s<span className="orange-text"> {this.props.mentorData.company} Placement Story</span></h1>
            <iframe src={this.props.mentorData.podcastLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <h4 className='HomeTitleIntro'><br />
              I've shared my entire placement story in the podcast which includes my preparation strategies as well as my interview experience! <br /><br />
              Ps. I've launched my Super 30 batch, whom I'll get 100% placement ready in the next 3 months!
            </h4>
            <div className='homeButBox'>
              <a
                href={this.props.mentorData?.formLink}
                target='_blank' rel="noreferrer">
                <button className='btnhomeR'>
                  Apply now
                  <img
                    alt='Plane'
                    src={Plane}
                    className='PlaneInButton'
                  />
                </button>
              </a>
            </div>
          </div>}

        {!this.props.mentorData.podcastLink &&
          <div className='podcastDetails'>
            <div>
              <i class="fa fa-calendar" aria-hidden="true"></i> <br />
              <b>Date</b><br />
              {this.props.mentorData.podcastDate || "Coming Soon"}
            </div>
            <div>
              <i class="fa fa-clock-o" aria-hidden="true"></i> <br />
              <b>Time</b><br />
              {this.props.mentorData.podcastTime || "Coming Soon"}
            </div>
            <div>
              <i class="fa fa-money" aria-hidden="true"></i> <br />
              <b>Access</b><br />
              Invite Only
            </div>
            <div>
              <i class="fa fa-spinner" aria-hidden="true"></i> <br />
              <b>Duration</b><br />
              {this.props.mentorData.podcastDuration || "1 hr"}
            </div>
          </div>
        }
      </div>
    );
  }
}