import { isMobile } from 'react-device-detect';

export const REGISTER_DET = 'http://discovery.explorin.io';
export const GET_IP_URL = 'https://api.ipify.org/?format=json';
export const CLUB_SERVER = 'https://clubs.explorin.io';
export const HOME_DET = 'http://discovery.explorin.io';

export const PATH = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? "local" : "/static/media/";

export const link = (isMobile ? 'https://wa.me/919950409065/?text=' : 'https://web.whatsapp.com/send?phone=919950409065&text=');
export const msg = "Hey! I want assistance.";

export default isMobile;

export const GOOGLE_REG_FORM = 'https://bit.ly/3-month-cohort-program';
export const GOOGLE_MENTOR_REG_FORM = 'https://bit.ly/mentorship-opportunity';
export const GOOGLE_ANALYST_REG_FORM = 'https://forms.gle/XiRSwM3fVsmEvKJZ9';