// react
import React from 'react';

//images
import whatWillYouGet from '../../static/images/MentorSpecific/whatWillYouGet.svg';

// css
import '../../static/styles/components/BasePage/WhyDetSection.css';

export default function Outcomes({ mentorData }) {

  return (
    <>
      <div className='outcomes'>
        <h2>
          What will you get in<span className='orange-text'> 2-month Placement Cohort</span>?
        </h2>
        {/* <p className="heading-ans">
          Daily Preparation through Explorin's Placement Support and {mentorData.firstName}'s Live Mentorship
        </p> */}
        <br /><br />
        <div className='OutcomesData'>
          <div className='Points'>
            <h5 className='hideInMobileScreenOnly'>Explorin's Weekend Coaching</h5>
            <i class="fa fa-check"></i> 100 hrs of Live Classes with IITian Coach <br />
            <i class="fa fa-check"></i> 300+ Practice Problems  <br />
            <i class="fa fa-check"></i> 12+ Weekly Contests  <br />
            <i class="fa fa-check"></i> Inter & Intra College Leaderboard <br />
            <i class="fa fa-check"></i> Fortnightly Cash Reward Ceremony <br />
            <i class="fa fa-check"></i> 24*7 Community Support <br />
            <i class="fa fa-check"></i> 4 Industry Knowledge Sessions
          </div>
          <div className='RightImg'>
            <h5 className='hideInMobileScreenOnly'>{mentorData.firstName}'s Weekday Mentorship</h5>
            <img src={whatWillYouGet} alt='nanodegree' />
          </div>
        </div>
        <a href={`/${mentorData?.lowerName}/cohort`} target='_blank' rel="noreferrer">
          <button className='button'>
            About Cohort
          </button>
        </a>
      </div>
    </>
  );
}
