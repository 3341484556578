// react imports
import React from "react";
import Faq from "react-faq-component";

//css
import '../../static/styles/components/BasePage/FAQs.css';

//images
import Plane from '../../static/images/Icons/plane_in_button.png';

const data = {
  rows: [
    {
      title: "1. What is Super 30?",
      content: `Super 30 is a batch of 30 deserving juniors, whom I would be personally training extensively for the next 2 months starting from 23rd June, for their upcoming on campus placements and internships.`,
    },
    {
      title: " 2. What types of questions would be asked in the Coding Contest?",
      content: `Easy-Medium level DSA questions would be asked.`,
    },
  ],
};

export default function FAQs({mentorData}) {
  return (
    <div className='faqs'>
      <h1 className='faqs-title orange-text'>
        Have more questions?
      </h1>
      <div className='question-div'>
        <Faq
          data={data}
        />
      </div>
      <br /><br />
      <a href={mentorData.storyFormLink} className="pre-bk" target='_blank' rel="noreferrer">
        <button className='button sidebtn'>
          Apply now
          <img
            alt='plane-icon'
            src={Plane}
            className='PlaneInButton'
          />
        </button>
      </a>
    </div>
  );
}
