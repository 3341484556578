import Raj from '../images/MentorSpecific/homePageRaj.svg';
import RajStory from '../images/MentorSpecific/homePageStoryRaj.svg';
import Kashish from '../images/MentorSpecific/homePageKashish.svg';
import KashishStory from '../images/MentorSpecific/homePageStoryKashish.svg';
import Abhishek from '../images/MentorSpecific/homePageAbhishek.svg';
import AbhishekStory from '../images/MentorSpecific/homePageStoryAbhishek.svg';
import Sahil from '../images/MentorSpecific/homePageSahil.svg';
import SahilStory from '../images/MentorSpecific/homePageStorySahil.svg';
import Jatin from '../images/MentorSpecific/homePageJatin.svg';
import JatinStory from '../images/MentorSpecific/homePageStoryJatin.svg';
import Dwitrisha from '../images/MentorSpecific/homePageDwitrisha.svg';
import DwitrishaStory from '../images/MentorSpecific/homePageStoryDwitrisha.svg';
import Hardik from '../images/MentorSpecific/homePageHardik.svg';
import HardikStory from '../images/MentorSpecific/homePageStoryHardik.svg';
import Riya from '../images/MentorSpecific/homePageRiya.svg';
import RiyaStory from '../images/MentorSpecific/homePageStoryRiya.svg';
import Nishtha from '../images/MentorSpecific/homePageNishtha.svg';
import NishthaStory from '../images/MentorSpecific/homePageStoryNishtha.svg';
import Dev from '../images/MentorSpecific/homePageDev.svg';
import DevStory from '../images/MentorSpecific/homePageStoryDev.svg';
import Google from '../images/MentorSpecific/logo/google.svg';
import Airbnb from '../images/MentorSpecific/logo/airbnb.svg';

//utils
import { BATCH } from './batchData';
import { SESSIONS } from './previousSessions';

export const MENTORS = {
  raj: {
    name: 'Raj Anand',
    firstName: 'Raj',
    lowerName: 'raj',
    company: 'Amazon',
    college: 'VIT',
    headSubTitle: "VIT on-campus season is coming soon! I've been at your exact same place preparing for placements last year. I've been highly passionate about technology since the early years of my college life. I had been part of Codechef & VITrendz & worked with companies like Growth School & currently intern at Amazon, I've been mentoring juniors for a long time & now ready to train my Super 30 juniors.",
    storyText: "During my early college years I always felt like I knew nothing compared to my peers. Seeing people in my close circle overachieving at life so quickly made me question my skillset. I know a lot of you might feel the same way. But, everyday I strived to be better instead of giving up & my continuous efforts enabled me to crack Amazon, my dream company!",
    collab: "In collaboration with IEEE ComSoc and TEC",
    image: Raj,
    formLink: 'https://forms.gle/PWPSYNvNgcgCCwJHA',
    _1PassSeats: '10',
    _2PassSeats: '20',
    usp: 'Raj will be taking 30 Placement & Internship aspirants only',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC Target >10lpa',
    preData3: 'Any branch',
    preData4: 'English/Hindi/Telugu speaker',
    myStoryImg: RajStory,
    storyFormLink: 'https://forms.gle/RZDoRAuo67aF9A848',
    podcastDate: '8Th June',
    podcastTime: '9 PM',
    podcastDuration: '3 hrs',
  },
  kashish: {
    name: 'Kashish Agrawal',
    firstName: 'Kashish',
    lowerName: 'kashish',
    company: 'JPMC',
    college: 'SRM',
    headSubTitle: "SRM on-campus season is coming soon! I've been at your exact same place preparing for placements last year. I've been highly passionate about technology since the early years of my college life. I had been Head of Core Team at CodeChef SRM NCR chapter & worked with companies like Applex, Highradius & now at JPMC, I've been mentoring juniors for a long time & now ready to train my Super 30 juniors.",
    storyText: "When I wasn't able to clear JEE after my 12th I was disheartened. This setback fueled my ambition in life to make my parents proud! I consistently worked hard to grow myself from the beginning of my college life. My first big breakthrough was my foreign internship in 2nd year at JINR Russia. After that I didn't look back & finally cracked JP Morgan during SRM on-campus placements!",
    collab: "In collaboration with Gfg, SRM Chapter",
    image: Kashish,
    formLink: 'https://forms.gle/FhT3EW38zDFZCTe46',
    _1PassSeats: '10',
    _2PassSeats: '20',
    usp: 'Kashish will be taking 30 Placement & Internship aspirants only',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC Target >10lpa',
    preData3: 'Any branch',
    preData4: 'English/Hindi speaker',
    myStoryImg: KashishStory,
    storyFormLink: 'https://forms.gle/ykZ4kCF3cAHgaN899',
    podcastDate: '12th June',
    podcastTime: '9 PM',
    podcastDuration: '3 hrs',
  },
  abhishek: {
    name: 'Abhishek Agarwal',
    firstName: 'Abhishek',
    lowerName: 'abhishek',
    company: 'Media.net',
    college: 'BIT Mesra',
    about1: 'Upcoming SDE Media.net',
    about2: 'BIT Mesra - 2023',
    headSubTitle: "BIT Mesra on-campus season is coming soon! I've been at your exact same place preparing for placements last year. I've been highly passionate about programming since the early years of my college life. I have been selected in ICPC regionals & candidate masters at Codeforces. I've been mentoring juniors for a long time & now ready to train my Super 30 juniors.",
    storyText: "Coming from a humble family background I knew nothing about computers. When I entered BIT Mesra in my first year, I literally asked a friend what Ctrl + C does? But, I always had the hunger to learn & grow! I never allowed my background to be an obstacle towards my dreams & finally cracked Media.net during BIT Mesra campus placements!",
    collab: "In collaboration with IEEE and ACM",
    image: Abhishek,
    formLink: 'https://forms.gle/gBrTSMeyrK1E3HGs5',
    _1PassSeats: '10',
    _2PassSeats: '20',
    usp: 'Abhishek will be taking 30 Placement & Internship aspirants only',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC Target >10lpa',
    preData3: 'Any branch',
    preData4: 'English/Hindi speaker',
    myStoryImg: AbhishekStory,
    storyFormLink: 'https://forms.gle/r8sETEMaUyXHDf896',
    podcastDate: '9th June',
    podcastTime: '9 PM',
    podcastDuration: '3 hrs',
    trailerLink: 'https://www.youtube.com/embed/AbpkT7zcln0',
    previousVideo: SESSIONS['abhishek'],
    visionVideo: 'true',
  },
  sahil: {
    name: 'Sahil Chugh',
    firstName: 'Sahil',
    lowerName: 'sahil',
    company: 'Barclays UK',
    college: 'Thapar',
    about1: 'Upcoming SDE Barclays, UK',
    about2: 'Thapar - 2023',
    headSubTitle: "Thapar on-campus season is coming soon! I've been at your exact same place preparing for placements last year. I've been highly passionate about technology since the early years of my college life. I have been part of several clubs & societies at Thapar & worked with companies like Microsoft, BlackRock. Soon, I'll be joining Barclays London Office as a full time Technology Analyst. I've been mentoring juniors for a long time & now ready to train my Super 30 juniors.",
    storyText: "During this visionary gathering, we will deep dive into the very essence of my Super 30 Placement Batch, revealing its core principles and what makes it the best opportunity for Thapar juniors to conquer the best coding placements.",
    image: Sahil,
    formLink: 'https://forms.gle/TWYqscBnDcPVccRHA',
    _1PassSeats: '10',
    _2PassSeats: '20',
    usp: 'Sahil will be taking 30 Placement & Internship aspirants only',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC Target >10lpa',
    preData3: 'Any branch',
    preData4: 'English/Hindi speaker',
    myStoryImg: SahilStory,
    storyFormLink: 'https://forms.gle/yQ7tdm92tfRVV3ut5',
    podcastDate: '20th June',
    podcastTime: '10 PM',
    podcastDuration: '1 hr',
    // trailerLink: 'https://www.youtube.com/embed/jXXi3i_d7Ek',
    // previousVideo: SESSIONS['sahil'],
    // visionVideo: 'true',
  },
  dwitrisha: {
    name: 'Dwitrisha',
    firstName: 'Dwitrisha',
    lowerName: 'dwitrisha',
    company: 'Oracle',
    college: 'MIT',
    headSubTitle: "MIT Manipal on-campus season is coming soon! I've been at your exact same place preparing for placements last year. I've been highly passionate about technology since the early years of my college life. I was First Gold Microsoft Learn Student Ambassador from MIT Manipal & worked at companies like Microsoft & Oracle. I've been mentoring juniors for a long time & now ready to train my Super 30 juniors.",
    storyText: "I have always been fascinated by the ability to create software applications that could solve real-world problems and make people's lives easier. But, not getting into IITs, it became my motivation to work hard from the beginning of my college life. Soon I was First Gold Microsoft Learn Student Ambassador from MIT Manipal & cracked my dream placement at Oracle.",
    collab: "In collaboration with ISTE and ADG",
    image: Dwitrisha,
    formLink: 'https://forms.gle/RhjAK2AAG4se29xp6',
    _1PassSeats: '10',
    _2PassSeats: '20',
    usp: 'Dwitrisha will be taking 30 Placement & Internship aspirants only',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC Target >10lpa',
    preData3: 'Any branch',
    preData4: 'English/Hindi speaker',
    myStoryImg: DwitrishaStory,
    storyFormLink: 'https://forms.gle/5HgA8Gz7gKSjiAhi8',
    podcastDate: '11th June',
    podcastTime: '9 PM',
    podcastDuration: '3 hrs',
  },
  jatin: {
    name: 'Jatin',
    firstName: 'Jatin',
    lowerName: 'jatin',
    company: 'Monocept',
    college: 'SSTC',
    headSubTitle: "SSTC on-campus season is coming soon! I've been at your exact same place preparing for placements last year. I've been highly passionate about technology since the early years of my college life. I qualified ICPC, won various hackathons & worked with companies like Codechef & TRAY. I've been mentoring juniors for a long time & now ready to train my Super 30 juniors.",
    storyText: "During this visionary gathering, we will deep dive into the very essence of my Super 30 Placement Batch, revealing its core principles and what makes it the best opportunity for SSTC juniors to conquer the best coding placements.",
    image: Jatin,
    formLink: 'https://forms.gle/44V6vr5dWRmCYR8p7',
    _1PassSeats: '4',
    _2PassSeats: '20',
    usp: 'Jatin will be taking 30 Placement & Internship aspirants only',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC Target >10lpa',
    preData3: 'Any branch',
    preData4: 'English/Hindi speaker',
    myStoryImg: JatinStory,
    storyFormLink: 'https://forms.gle/qPRx6Y5F7SSorvDV8',
    podcastDate: '20th June',
    podcastTime: '8 PM',
    podcastDuration: '1 hr',
  },
  riya: {
    name: 'Riya Dev Varshney',
    firstName: 'Riya',
    lowerName: 'riya',
    company: 'Google',
    college: 'DTU',
    headSubTitle: "DTU on-campus season is coming soon! I've been at your exact same place preparing for placements last year. I've been highly passionate about technology since the early years of my college life. I had been part of DCODER & worked with companies like Microsoft & currently placed at Google, I've been mentoring juniors for a long time & now ready to train my Super 30 juniors.",
    storyText: "I dedicated myself to enhancing my coding skills and exploring different areas within the field of technology. I started participating in various hackathons and found success by winning six of them. Later cracked intern at Microsoft and my full time offer at my dream company Google.",
    collab: "",
    image: Riya,
    formLink: 'https://forms.gle/YxBpwWcDHvdutuqRA',
    _1PassSeats: '10',
    _2PassSeats: '20',
    usp: 'Dwitrisha will be taking 30 Placement & Internship aspirants only',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC Target >10lpa',
    preData3: 'Any branch',
    preData4: 'English/Hindi speaker',
    myStoryImg: RiyaStory,
    storyFormLink: 'https://forms.gle/i7cd9PnjEkpJuYnd9',
    podcastDate: '',
    podcastTime: '',
    podcastDuration: '3 hrs',
  },
  hardik: {
    name: 'Hardik Srivastava',
    firstName: 'Hardik',
    lowerName: 'hardik',
    company: 'JPMC',
    college: 'SRM',
    headSubTitle: "SRM on-campus season is coming soon! I've been at your exact same place preparing for placements last year. I’ve been highly passionate about technology since the early years of my college life. I had been part of Google Developer Club SRMIST & worked with companies like Samsung & currently placed at JP Morgan, I've been mentoring juniors for a long time & now ready to train my Super 30 juniors",
    storyText: "My motivation has always been my parents as I have seen them work so hard to make me capable of whatever I am right now. The mantras that I have always followed are that you need to work hard to stand out! I did my MITACS research intern in Canada & later cracked JP Morgan in SRM on-campus.",
    collab: "",
    image: Hardik,
    formLink: 'https://forms.gle/zmtBwG3L3PALJbfUA',
    _1PassSeats: '10',
    _2PassSeats: '20',
    usp: 'Dwitrisha will be taking 30 Placement & Internship aspirants only',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC Target >10lpa',
    preData3: 'Any branch',
    preData4: 'English/Hindi speaker',
    myStoryImg: HardikStory,
    storyFormLink: 'https://forms.gle/1tAaFNSxgQ2a25eB7',
    podcastDate: '',
    podcastTime: '',
    podcastDuration: '3 hrs',
  },
  nishtha: {
    name: 'Nishtha Goyal',
    firstName: 'Nishtha',
    lowerName: 'nishtha',
    company: 'Google',
    about1: 'Upcoming SWE Google',
    about2: 'STEP & SWE intern, Google',
    logo: Google,
    college: 'IGDTUW',
    headSubTitle: "As a former Google STEP and SWE intern, Nishtha has the experience and expertise to help you succeed in your career. She has mentored over 200 students in the domain of DSA and web development. She has won more than 25+ national hackathons and has a unique perspective on how to approach problems and find creative solutions. With her guidance, you can be sure you're getting the best possible training.",
    image: Nishtha,
    _1PassSeats: '0',
    _2PassSeats: '0',
    formLink: 'https://forms.gle/1rnjbniEo12ubuSr7',
    usp: 'I was the Top-50 Major League Hacking (MLH) Fellow, 2022',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC target >15lpa',
    preData3: 'All Branches',
    preData4: 'English/Hindi speaker',
    myStoryImg: NishthaStory,
    storyFormLink: 'https://forms.gle/6ZMPJpVpeicnBDaw7',
    podcastLink: 'https://www.youtube.com/embed/ajIeofviZP0',
    trailerLink: "https://www.youtube.com/embed/Own2djdhFak",
    batch: BATCH['nishtha'],
    whatsapp: "https://chat.whatsapp.com/B8bdEGmKfcGJmOMhRFjewX",
    visionVideo: "https://www.youtube.com/embed/S6Tmd9gYSUg",
    additionalVideo: 'https://www.youtube.com/embed/Q5HEVhe_nH0',
    reserved: "0",
    seatsClaimed: "30",
  },
  // akash: {
  //   name: 'Akash Gautam ',
  //   firstName: 'Akash',
  //   lowerName: 'akash',
  //   company: 'Quinbay',
  //   about1: 'Upcoming SDE Quinbay',
  //   about2: 'Former Secretary Geeks for Geeks, KIIT',
  //   college: 'KIIT',
  //   headSubTitle: 'He is a dedicated and forward-thinking technology professional who is passionate about solving complex problems and driving business growth. He was the Secretary of GeeksforGeeks KIIT and believes in creating an inclusive and supportive mentorship culture.',
  //   image: Akash,
  //   formLink: 'https://forms.gle/1USAqC84ahoBKpBS8',
  //   usp: 'I was ranked AIR 53 in Google Hashcode 2022 and have a CGPA of 9.45',
  //   preData1: '1st, 2nd & 3rd year students',
  //   preData2: 'CTC target >15lpa',
  //   preData3: 'All Branches',
  //   preData4: 'English/Hindi speaker',
  //   myStoryImg: AkashStory,
  //   storyFormLink: 'https://forms.gle/hoGwoH261LATeUf58',
  //   podcastLink: 'https://www.youtube.com/embed/NyNPHhLwJfo',
  //   trailerLink: "https://www.youtube.com/embed/bahrwdaa3dw",
  //   liveTime: '@9-9:30 PM',
  //   whatsapp: "https://chat.whatsapp.com/DnZukVDVK3LDNgzgaTFjUJ",
  //   // previousVideo: SESSIONS['akash'],
  // },
  dev: {
    name: 'Dev Sharma ',
    firstName: 'Dev',
    lowerName: 'dev',
    company: 'Airbnb',
    about1: 'SDE Intern Airbnb',
    about2: 'Research Intern, Samsung R&D',
    logo: Airbnb,
    college: 'VIT Vellore',
    headSubTitle: 'He is a curious learner and developer out of true passion. He has been a Research intern with Samsung R&D and Software Engineer with several clubs and organisations. He has a track record of making his mentees highly confident in whatever goal they are chasing.',
    image: Dev,
    _1PassSeats: '0',
    _2PassSeats: '0',
    formLink: 'https://forms.gle/ChGJycJwdMBgEM549',
    usp: 'I won the "Achievers Award" by VIT for my exemplary performance at various National events.',
    preData1: '1st, 2nd & 3rd year students',
    preData2: 'CTC target >15lpa',
    preData3: 'All Branches',
    preData4: 'English/Hindi speaker',
    myStoryImg: DevStory,
    storyFormLink: 'https://forms.gle/5BVnMxdEn6VVfEHv9',
    podcastLink: 'https://www.youtube.com/embed/rjcBLn-QMWo',
    trailerLink: "https://www.youtube.com/embed/0Yzpwm7r3xI",
    liveTime: '@10 PM',
    batch: BATCH['dev'],
    whatsapp: "https://chat.whatsapp.com/HuDVMfgQ3lgHzItTYkaMdA",
    previousVideo: SESSIONS['dev'],
    visionVideo: "https://www.youtube.com/embed/nh2zB2JwoZw",
    additionalVideo: "https://www.youtube.com/embed/0BudEI04IVg",
    reserved: "0"
  },
}