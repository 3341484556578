// react
import React from 'react';
import { GOOGLE_REG_FORM } from '../../static/utils/applicationUrls';

//images
import Pricing1 from '../../static/images/Icons/Pricing-icon-1.svg';

// css
import '../../static/styles/components/BasePage/Pricing.css';

export default function Pricing({ mentorData }) {
  return (
    <>
      <div className='predict-page mentorSpecific-predict-page'>
        <div className='predict-head'>
          <div className='journey-heading'>
            Why
            <span className='orange-text'> {mentorData?.firstName || "Explorin"}'s Super 30 </span>?
            <span className='batch-seats'>{parseInt(mentorData?._1PassSeats) + parseInt(mentorData?._2PassSeats)} seats left</span>
          </div>
          <p className='predict-title'>Grab the best On-campus Offer with Daily Personalized Attention</p>
          <div className='price-boxes'>
            {mentorData?.lowerName !== 'nishtha' && mentorData?.lowerName !== 'dev' ? <>
              <div className='box free hideInMobileScreenOnly'>
                <h4>Self Paced Courses</h4>
                <h2>₹ 3K-6K</h2>
                <div className='usps'>
                  {mentorData?.lowerName === "jatin" && <>
                    <li><span className='usp-no'>&#10006;</span>No faculty mentor</li>
                    <li><span className='usp-no'>&#10006;</span>No internship certificate</li></>}
                  <li><span className='usp-yes'>&#10004;</span>Self paced</li>
                  <li><span className='usp-no'>&#10006;</span>No personalisation</li>
                  <li><span className='usp-no'>&#10006;</span>No live mentorship</li>
                  <li><span className='usp-no'>&#10006;</span>No live coaching</li>
                  <li><span className='usp-no'>&#10006;</span>No feedback </li>
                  <li><span className='usp-no'>&#10006;</span>No mock interviews</li>
                  <li><span className='usp-no'>&#10006;</span>No such exclusive contests</li>
                  <li><span className='usp-no'>&#10006;</span>No resume reviews</li>
                  <li><span className='usp-no'>&#10006;</span>No industry or alumni sessions</li>
                  <li><span className='usp-yes'>&#10004;</span>Anyone willing can purchase</li>
                  <li><span className='usp-yes'>&#10004;</span>Limited community support</li>
                </div>
              </div>
              <div className='box'>
                <p className='pass-name emi-absolute' >easy EMI</p>
                <h4>{mentorData?.firstName || "Explorin"}'s Super 30</h4>
                <h2>{mentorData?.lowerName === "jatin" ? "₹ 7,999" : "₹ 12K"} <span className='original-price'>₹ 15K</span></h2>
                <div className='usps'>
                  {mentorData?.lowerName === "jatin" && <>
                    <li><span className='usp-yes'>&#10004;</span>Dr. Siddhartha as faculty mentor</li>
                    <li><span className='usp-yes'>&#10004;</span>SSTC approved intern certificate</li>
                  </>}
                  <li><span className='usp-yes'>&#10004;</span>8+4 weeks program</li>
                  <li><span className='usp-yes'>&#10004;</span>{mentorData?.college || "College"} personalised <span className='hideInMobileScreenOnly'> curriculum </span></li>
                  <li><span className='usp-yes'>&#10004;</span>Live logic building with {mentorData?.firstName || "mentor"}</li>
                  <li><span className='usp-yes'>&#10004;</span>Live DSA with IITian coach</li>
                  <li><span className='usp-yes'>&#10004;</span>1:1 feedbacks by {mentorData?.firstName || "mentor"}</li>
                  <li><span className='usp-yes'>&#10004;</span>1:1 mock interviews <span className='hideInMobileScreenOnly'> with {mentorData?.firstName || "mentor"}</span></li>
                  <li><span className='usp-yes'>&#10004;</span>Compete with top {mentorData?.college}ians</li>
                  <li><span className='usp-yes'>&#10004;</span>Resume <span className='showInMobileScreenOnly'>assistance</span><span className='hideInMobileScreenOnly'> building and reviews</span></li>
                  <li><span className='usp-yes'>&#10004;</span>Industry and alumni sessions</li>
                  <li><span className='usp-yes'>&#10004;</span>Only for deserving 30</li>
                  <li><span className='usp-yes'>&#10004;</span>24*7 community of peers</li>
                </div>
              </div>
              <div className='box'>
                <h4>Other DSA Courses</h4>
                <h2>₹ 20<span className='hideInMobileScreenOnly'>K</span>-30K</h2>
                <div className='usps'>
                  {mentorData?.lowerName === "jatin" && <>
                    <li><span className='usp-no'>&#10006;</span>No faculty mentor</li>
                    <li><span className='usp-no'>&#10006;</span>No internship certificate</li></>}
                  <li><span className='usp-yes'>&#10004;</span>24+ weeks program</li>
                  <li><span className='usp-no'>&#10006;</span>No personalisation</li>
                  <li><span className='usp-no'>&#10006;</span>No live session with your senior</li>
                  <li><span className='usp-yes'>&#10004;</span>Limited live coaching</li>
                  <li><span className='usp-no'>&#10006;</span>No 1:1 feedback sessions</li>
                  <li><span className='usp-no'>&#10006;</span>No 1:1 mock interviews</li>
                  <li><span className='usp-no'>&#10006;</span>No <span className='hideInMobileScreenOnly'> such </span> exclusive contests </li>
                  <li><span className='usp-no'>&#10006;</span>No focus on resumes</li>
                  <li><span className='usp-yes'>&#10004;</span>Limited industry sessions</li>
                  <li><span className='usp-yes'>&#10004;</span>Anyone willing can purchase</li>
                  <li><span className='usp-yes'>&#10004;</span>Community support available</li>
                </div>
              </div>
            </> :
              <div className='box'>
                <p className='pass-name'>Standard Pass</p>
                <h2>₹ 5000 <span> OFF</span></h2>
                <h6><img src={Pricing1} height='23' alt='price-icons' />{mentorData?._1PassSeats} seats left</h6>
                <div>
                  <span className='orange-text'>₹ 15K</span>
                  <span className='early-bird'>25% Scholarship</span>
                </div>
                <h1><p>₹ 5000/month</p></h1>
                {/* <a href={mentorData?.formLink} target='_blank' rel="noreferrer">
                  <button className='button sidebtn' onClick={() => makeRequest()}>
                    Apply now
                  </button>
                </a> */}
              </div>}
          </div>
          {mentorData?.lowerName === "nishtha" || mentorData?.lowerName === "dev" ?
            <div className='pricing-btn-div' style={{ display: 'flex', gap: '0.3em' }}>
              <a href={mentorData?.formLink || GOOGLE_REG_FORM} target='_blank' rel="noreferrer">
                <button className='button sidebtn'>
                  Apply now
                </button>
              </a>
              <a href={`/${mentorData?.lowerName}/mybatch`} >
                <button className='button sidebtn'>
                  My Super 30
                </button>
              </a></div> : <a href={mentorData?.formLink} target='_blank' rel="noreferrer">
              <button className='button sidebtn'>
                Apply now
              </button>
            </a>}
          <h6><span className='orange-text'>**Guarantee: You will get 100% scholarship if you find such level of personalized placement preparation for your college below this price anywhere</span></h6>
        </div>
        <div className='guarantee'>
          <span>₹</span> 7 days Money back Guarantee*</div>
      </div>
    </>
  );
}
