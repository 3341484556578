// react
import React from 'react';
// css
import '../../static/styles/components/DET/Prerequisites.css';
// images
import NoTechnical from '../../static/images/Icons/det-no-technical-icon.svg';
import Willingness from '../../static/images/Icons/det-willingness-icon.svg';
import Desire from '../../static/images/Icons/det-desire-icon.svg';
import Freshers from '../../static/images/Icons/det-freshers-icon.svg';

export default function Prerequisites() {
  return (
    <main className='prereq-section'>
      <h2>
        Who is this for ?
      </h2>
      <p className="heading-ans">
        Students aiming to crack their dream internship/placement
      </p>
      <section className='prereq-icons'>
        <div className='prereq-tc-r'>
          <div className='prereq-text-containers'>
            <img alt='no-technical' src={NoTechnical} />
            <p>
              Master DSA &<br className='showInMobileScreenOnly' /> CS Fundamentals
            </p>
          </div>
          <div className='prereq-text-containers'>
            <img alt='freshers' src={Freshers} />
            <p>
              Be guided by<br className='showInMobileScreenOnly' /> placed college seniors
            </p>
          </div>
        </div>
        <div className='prereq-tc-r'>
          <div className='prereq-text-containers'>
            <img alt='willingness' src={Willingness} />
            <p>
              Ace interviews <br className='showInMobileScreenOnly' />with Confidence
            </p>
          </div>
          <div className='prereq-text-containers'>
            <img alt='desire' src={Desire} />
            <p>
              Compete and Grow with your College Peers
            </p>
          </div>
        </div>
      </section>
    </main>
  );
}