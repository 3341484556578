// react imports
import React, { Component } from 'react';

//images
import logo from '../../static/images/logo.png';

// css
import '../../static/styles/components/BasePage/NavBarWeb.css';

//urls
import { GOOGLE_REG_FORM } from '../../static/utils/applicationUrls';
import { Link } from 'react-router-dom';

export default class NavBarWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
    };
  }

  // Adds an event listener when the component is mount.
  async componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;
    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
  };

  render() {
    return (
      <div className='WebNavBarHideInMobile'>
        <div className={this.state.visible ? 'WebNavbar' : 'navhide'}>
          <div
            className='WebNavbar__logo'
            onClick={(event) => {
              event.preventDefault();
              window.location.href = this.props?.mentorData?.lowerName ? `/${this.props?.mentorData?.lowerName}` : "/";
            }}>
            <div className='WebNavbar__logo--set'>
              <img className='logo' src={logo} alt='logo'></img>
            </div>
          </div>
          <div className='WebNavbar__menu'>
            {window.location.pathname.includes('/mystory') || window.location.pathname.includes('/codefest') ?
              <>
                <Link to={`/${this.props?.mentorData?.lowerName}/cohort`}>
                  <div className='WebNavbar__menu--items'>
                    About Cohort
                  </div>
                </Link>
                <Link to={`/${this.props?.mentorData?.lowerName}`}>
                  <div className='WebNavbar__menu--items'>
                    My Profile
                  </div>
                </Link>
              </> :
              <>
                {window.location.pathname !== '/' &&
                  <>
                    <Link to={`/${this.props?.mentorData?.lowerName}/cohort`}>
                      <div className='WebNavbar__menu--items'>
                        About Cohort
                      </div>
                    </Link>
                    <Link to={this.props?.mentorData?.lowerName === "nishtha" || this.props?.mentorData?.lowerName === "dev" ? `/${this.props?.mentorData?.lowerName}/mystory` : `/${this.props?.mentorData?.lowerName}/codefest`}>
                      <div className='WebNavbar__menu--items'>
                        {this.props?.mentorData?.lowerName === "nishtha" || this.props?.mentorData?.lowerName === "dev" ?
                          "My Placement Story" : this.props?.mentorData?.lowerName !== "abhishek" && this.props?.mentorData?.lowerName !== "jatin" && this.props?.mentorData?.lowerName !== "sahil" && "My CodeFest"}
                      </div>
                    </Link>
                    {this.props.mentorData.batch && !window.location.pathname.includes('/mybatch') && <Link to={`/${this.props?.mentorData?.lowerName}/mybatch`}>
                      <div className='WebNavbar__menu--items'>
                        My Super 30
                      </div>
                    </Link>}
                  </>}
                {window.location.pathname.includes('/mybatch') ?
                  <Link to={`/${this.props?.mentorData?.lowerName}`}>
                    <div className='WebNavbar__menu--items'>
                      My Profile
                    </div>
                  </Link> : ""
                }
              </>}
            {(window.location.pathname.includes('/mystory') || window.location.pathname.includes('/codefest')) && this.props.mentorData.batch && <Link to={`/${this.props?.mentorData?.lowerName}/mybatch`}>
              <div className='WebNavbar__menu--items'>
                My Super 30
              </div>
            </Link>}
            {window.location.pathname.includes('/mystory') || window.location.pathname.includes('/codefest') ? <>
              {this.props.mentorData.trailerLink ?
                <a
                  href={this.props?.mentorData?.formLink}
                  className='WebNavbar__menu--items'
                  target='_blank' rel="noreferrer">
                  <button className='btn--ticket--golden'>
                    Apply now
                  </button>
                </a>
                :
                <a
                  href={this.props?.mentorData?.storyFormLink}
                  className='WebNavbar__menu--items'
                  target='_blank' rel="noreferrer">
                  <button className='btn--ticket--golden'>
                    Book your seat
                  </button>
                </a>}
            </>
              :
              <a
                href={this.props?.mentorData?.formLink || GOOGLE_REG_FORM}
                className='WebNavbar__menu--items'
                target='_blank' rel="noreferrer">
                <button className='btn--ticket--golden'>
                  Apply now
                </button>
              </a>
            }
          </div>


        </div>
      </div>
    );
  }
}
