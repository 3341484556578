// react imports
import React from 'react';

// images
import DETImage from '../../static/images/Backgrounds/why_det_bg_web.png';
import Plane from '../../static/images/Icons/plane_in_button.png';
import CodeFest from '../../static/images/Icons/codefest.svg';

// css
import '../../static/styles/components/BasePage/WhyDetSection.css';

export default function CodeFestCertificate({ mentorData }) {

  return (
    <div className='WhyDetBase'>
      <div className='democirclediv'>
        <div className='democircle'></div>
      </div>
      <div className='temp'>
        <img
          alt='why-det-bg'
          className='WhyDetBackground'
          src={DETImage}
        />
      </div>
      <div className='WhyDetData'>
        <div className='WhyDetText'>
          <h1 className='WhyDetTitle'>
            Earn the CodeFest Completion<span className='orange-text'> Certificate </span>
          </h1>
          <div className='WhyDetIntroDiv'>
            <p className='WhyDetIntro'>
              This certificate would be awarded to you for demonstrating outstanding dedication and perseverance in the pursuit of your dream on-<br />campus placement.
            </p>
          </div>
          <a href={mentorData?.storyFormLink} target='_blank' rel="noreferrer">
            <button className='button'>
              Book your Seat
              <img
                alt='plane-icon'
                src={Plane}
                className='PlaneInButton'
              />
            </button>
          </a>
        </div>
        <div className='WhyDetImages'>
          <img src={CodeFest} alt='nanodegree' />
          <a href={mentorData?.storyFormLink} target='_blank' rel="noreferrer">
            <button className='button showInMobileScreenOnly'>
              Book your Seat
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}
