// react imports
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';

//css
import '../../static/styles/components/BasePage/HomeSection.css';

//images
import Plane from '../../static/images/Icons/plane_in_button.png';

//components
import BatchCard from './BatchCard';

export default function Batch({ mentorData }) {
  return (
    <div className='HomePage MentorStory batchPage'>
      <h2 className='my-super-30'>My Super 30 Batch
        {mentorData?.lowerName !== "nishtha" &&
          <span className='batch-seats'>{parseInt(mentorData?._1PassSeats) + parseInt(mentorData?._2PassSeats)} seats left</span>}
      </h2>
      <h5 className='my-super-30-desc'>Here are the deserving students selected for my exclusive Super 30</h5>
      {mentorData?.lowerName !== "nishtha" && mentorData?.batch?.map((val) => {
        return (
          <BatchCard data={val} />
        );
      })}
      {mentorData?.lowerName === "nishtha" &&
        <Tabs defaultActiveKey="1">
          <Tab eventKey='1' title={mentorData?.seatsClaimed + ' Seats Claimed'}>
            {mentorData?.batch?.map((val) => {
              return (
                <BatchCard data={val} />
              );
            })}
            <br />
          </Tab>
          <Tab eventKey='2' title={mentorData.reserved + ' Seats Reserved'}>
            <BatchCard name={`${mentorData.reserved} Seats Reserved`} />
            <br />
          </Tab>
          <Tab eventKey='3' title={parseInt(mentorData?._1PassSeats) + parseInt(mentorData?._2PassSeats) + ' Seats Remaining'}>
            <BatchCard name={`${parseInt(mentorData?._1PassSeats) + parseInt(mentorData?._2PassSeats)} Seats Remaining`} />
            <br />
          </Tab>
        </Tabs>}
      <a
        href={mentorData?.formLink} className='apply-btn'
        target='_blank' rel="noreferrer">
        <button className='btnhomeR'>
          Apply now
          <img
            alt='Plane'
            src={Plane}
            className='PlaneInButton'
          />
        </button>
      </a>
    </div>
  );
}
