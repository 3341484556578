import axios from 'axios';
import { CLUB_SERVER, GET_IP_URL } from './applicationUrls';
export const Home = "Home Section";
export const NavBarMobPage = "Navigation Bar Mobile";
export const NavBarWebPage = "Navigation Bar Web";
export const StartJourneyPage = "Start Journey Section";
export const WhyDetPage = "Why DET Section";
export const IntropagebenefitPage = "Intro Page Benefit Section";
export const IntropagedetPage = "Intro Page DET Section";

export const getIP = async () => {
    const res = await axios.get(GET_IP_URL)
    console.log(res.data);
    return res.data
}

export const sendDataTOServer = async (data) => {
    var requestdata = JSON.stringify(data);
    var config = {
    method: 'post',
    url: CLUB_SERVER+"/get_visitor_info",
    headers: { 
        'Content-Type': 'application/json'
    },
    data : requestdata
    };

    axios(config)
    .then(function (response) {
    console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
    console.log(error);
    });
}