import React from "react";

//images 
import Plane from '../../static/images/Icons/plane_in_button.png';

export default function MeetMeLive({ mentorData }) {

  // const trigger = (e) => {
  //   const send = e.target.phone.value + " - " + mentorData.name
  //   const number = "+91" + e.target.phone.value
  //   const data = {
  //     "names": [send],
  //     "numbers": [
  //       "+919410710085",
  //       number,
  //     ],
  //   }
  //   axios.post('https://clubs.explorin.io/api/send_whatsapp_message', data)
  // }
  return (
    <>
      {/* <div className="meet-me-live">
        <h2>Meet me <span className="orange-text">Live </span> {mentorData.liveTime ? mentorData.liveTime : "in the Upcoming Webinar"}</h2>
        <h6>I'm excited to answer all your questions and provide personalized guidance to help you succeed. </h6>
        <h4>Interested?</h4>
        <h5>Book your FREE seat now!</h5>
        <form onSubmit={(e) => trigger(e)}>
          <input name='phone' type="tel" placeholder="10 digit whatsapp number..." pattern="[0-9]{10}" />
          <input type="submit" value="Confirm" />
        </form>
      </div> */}
      {mentorData.whatsapp && mentorData.lowerName !== "dev" &&
        <div className="meet-me-live meet-me">
          <h2>Meet me <span className="orange-text">Live </span> {mentorData.liveTime ? mentorData.liveTime : "in the Upcoming Webinar"}</h2>
          <h6>I'm excited to answer all your questions and provide personalized guidance to help you succeed. </h6>
          <h4>Interested?</h4>
          <h5>Book your FREE seat now!</h5>
          <a href={mentorData.whatsapp} target='_blank' rel="noreferrer">
            <button className='button sidebtn'>
              Join here
              <img
                alt='plane-icon'
                src={Plane}
                className='PlaneInButton'
              />
            </button>
          </a>
        </div>
      }
    </>

  );
}