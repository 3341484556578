// react imports
import React from "react";
import { useParams } from "react-router";

// css
import '../static/styles/common/landing.css';
import '../static/styles/common/CommonElements.css';
import '../static/styles/common/MediaQue.css';

import '../static/styles/common/MentorSpecific.css';

// local imports
import NavBarWeb from '../components/BasePage/NavBarWeb';
import NavBarMob from '../components/BasePage/NavBarMob';
import Chat from '../components/BasePage/ChatWithUs';
import Batch from "../components/MentorPage/Batch";

// utils
import { MENTORS } from '../static/utils/mentors';

export default function MentorBatch() {
  const { mentorId } = useParams();
  const mentorData = MENTORS[mentorId]

  return (
    <>
      {mentorData.batch ?
        <div className='basesection'>
          <NavBarWeb mentorData={mentorData} />
          <NavBarMob mentorData={mentorData} />
          <Batch mentorData={mentorData} />
          <Chat />
        </div>
        :
        (
          window.location.replace("https://placement.explorin.io")
        )}
    </>
  );
}
