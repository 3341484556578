// react
import React from 'react';

//images
import whatWillYouGetCodeFest from '../../static/images/MentorSpecific/whatWillYouGetCodeFest.svg';

// css
import '../../static/styles/components/BasePage/WhyDetSection.css';

export default function WhatWillYouGet({mentorData}) {

  return (
    <>
      <div className='outcomes what-you-get'>
        <h2>
          <span className='orange-text'>What </span>will you get ?
        </h2>
        <p className="heading-ans">
        3 hours of Live Placement Training from {mentorData.firstName}
        </p>
        <br /><br />
        <div className='OutcomesData'>
          <div className='RightImg'>
            <img src={whatWillYouGetCodeFest} alt='nanodegree' />
          </div>
        </div>
      </div>
    </>
  );
}
