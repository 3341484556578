// react
import React from 'react';

// css
import '../../static/styles/components/BasePage/ExplorinEmpire.css';

// images
import Background from '../../static/images/Backgrounds/explorin-empire-bg.svg';
import Plane from '../../static/images/Icons/plane_in_button.png';
import MentorsOverview from '../../static/images/Icons/home-empire-section.png';
import MentorsOverviewMobile from './MentorsOverviewMobile';

// urls
import { GOOGLE_REG_FORM } from '../../static/utils/applicationUrls';
import { StartJourneyPage, getIP, sendDataTOServer } from '../../static/utils/pageNames';

export default function ExplorinEmpire({mentorData}) {
  async function makeRequest() {
    const get_ip = await getIP()
    const data = { "ip": get_ip, "page": StartJourneyPage }
    console.log("Start Joruney", data)
    return await sendDataTOServer(data)
  }

  return (
    <>
      <div className='empire-container'>
        <div className='empire-page'>
          <div className='empire-content'>
            <div className='empire-heading'>
              Get Industry Insights with<br /><span className='orange-text'>Alum & Founders</span>
            </div>
            <div className='text'>
              Update yourself with the latest technologies and Industry Insights with the founders and industry experts and gain a competitive edge for acing interviews with Industry Mindset.
              <br /><br />
              <a href={mentorData?.formLink || GOOGLE_REG_FORM} target='_blank' rel="noreferrer">
                <button className='button sidebtn hideInMobileScreenOnly' onClick={() => makeRequest()}>
                  Apply now
                  <img
                    alt='plane-icon'
                    src={Plane}
                    className='PlaneInButton'
                  />
                </button>
              </a>
            </div>
            <MentorsOverviewMobile />
          </div>
          <img
            src={MentorsOverview}
            className='empire-agent'
            alt='agent'
          />
          <img
            src={Background}
            className='empire-bg'
            alt='gradient'
          />
        </div>
      </div>
    </>
  );
}
